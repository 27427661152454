import { APIError, ClientSideErrorCodes } from "@ui/clients";
import { WaWiCountry } from "../../country/country";
import { internalLogout } from "../guard";
import { abortRequests, signal } from "../redirectController";
import { createAuthHeaders } from "../user";
export type TokenResponse = {
    expires_at: number;
    access_token: string;
    locationsByModule: Record<string, string[]>;
    userCountries: string[];
};

// The value doesn't change during the runtime of the application, sufficient to get it once.
const wawiCountry = WaWiCountry.get();

const parseTokenResponse = async (response: Response): Promise<TokenResponse> => {
    const json = await response.json();
    const token = json.accessToken;
    const expires = json.expiresIn;
    const locationsByModule: Record<string, string[]> = {};
    for (const entry of Object.keys(json.allowedModules)) {
        locationsByModule[entry] = json.allowedModules[entry].locationIds;
    }
    const userCountries: string[] = json.userCountries;

    if (isNaN(expires) || !token || userCountries.length === 0) {
        throw Error("login response invalid");
    }
    return {
        expires_at: Date.now() + 1000 * expires,
        access_token: token,
        locationsByModule,
        userCountries,
    };
};

const login = async (
    aadCode: string,
    savedCodeVerifier: string,
    loginSuccessUrl: string,
    aadApplicationId: string,
    module: string,
): Promise<TokenResponse> => {
    const modules = [module];
    const response = await withWrapError(() =>
        fetch("/api/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                country: wawiCountry,
                code: aadCode,
                savedCodeVerifier,
                loginSuccessUrl,
                aadApplicationId,
                modules,
            }),
            signal,
        }),
    );

    if (!response.ok) {
        throw new APIError(response.status, response.statusText, await response.json().catch(() => undefined));
    }

    return parseTokenResponse(response);
};

const logout = async () => {
    const response = await withWrapError(() =>
        fetch("/api/logout", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            signal,
        }),
    );

    if (!response.ok) {
        throw new APIError(response.status, response.statusText);
    }
};

const refresh = async (module: string): Promise<TokenResponse> => {
    const authorizationHeaders = createAuthHeaders();

    const modules = [module];
    const response = await withWrapError(() =>
        fetch("/api/refresh", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                ...authorizationHeaders,
            },
            body: JSON.stringify({
                modules,
            }),
            signal,
        }),
    );

    if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
            await onUnauthorized();
        }
        throw new APIError(response.status, response.statusText);
    }

    return parseTokenResponse(response);
};

const withWrapError = async (f: () => Promise<Response>): Promise<Response> => {
    try {
        return await f();
    } catch (err) {
        if (err instanceof APIError) throw err;
        if (err instanceof Error) throw new APIError(ClientSideErrorCodes.UNEXPECTED, err.message);
        throw err;
    }
};

const onUnauthorized = async () => {
    await internalLogout();
    abortRequests();
    location.reload();
};

export const AuthService = {
    login,
    logout,
    refresh,
    onUnauthorized,
};
