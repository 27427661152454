/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { type Endpoint, HTTPMethod, type OmitOptionalDeep } from "../../../src/runtime";
import { type ListSettingsRequest } from "../configs/ListSettingsRequest";
import { type ListSettingsResponse, ListSettingsResponseSchema } from "../configs/ListSettingsResponse";

export type ListConfigsSettingsQueryParams = OmitOptionalDeep<ListSettingsRequest, "">;

export const ListConfigsSettings: Endpoint<ListSettingsResponse, void, void, ListConfigsSettingsQueryParams> = {
    method: HTTPMethod.GET,
    path: "/wam/configs/settings",
    parser: ListSettingsResponseSchema.parse,
};
