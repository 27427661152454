import { reactive } from "vue";
import { Location } from "@ui/clients/generated/wam/locations";

export enum LocationLoadingStatus {
    INITIAL = "INITIAL",
    DONE = "DONE",
}

type StoreState = {
    locations: Location[];
    status: LocationLoadingStatus;
};

// use this inside of "services"
export const LocationsStore = reactive<StoreState>({
    locations: [],
    status: LocationLoadingStatus.INITIAL,
});

// use this inside of "ui"
export const useLocationsStore = (): Readonly<StoreState> => LocationsStore;
