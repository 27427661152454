import { __, ShortcutCategory, Shortcuts } from "@ui/components";
import { SkeletonStore } from "./store";

const list: Array<ShortcutCategory> = [
    {
        categoryMsg: __("ui.libs.shortcut.category.actions"),
        shortcuts: [
            { shortcut: Shortcuts.k.alt, descriptionMsg: __("ui.libs.shortcut.show-shortcuts") },
            {
                shortcut: Shortcuts.Click.ctrl,
                descriptionMsg: __("ui.libs.shortcut.native.open-link-in-new-tab"),
                windowsOnly: true,
            },
            {
                shortcut: Shortcuts.Click.meta,
                descriptionMsg: __("ui.libs.shortcut.native.open-link-in-new-tab"),
                macOnly: true,
            },
            { shortcut: Shortcuts["+"].ctrl, descriptionMsg: __("ui.libs.shortcut.native.zoom-in"), windowsOnly: true },
            { shortcut: Shortcuts["+"].meta, descriptionMsg: __("ui.libs.shortcut.native.zoom-in"), macOnly: true },
            {
                shortcut: Shortcuts["-"].ctrl,
                descriptionMsg: __("ui.libs.shortcut.native.zoom-out"),
                windowsOnly: true,
            },
            { shortcut: Shortcuts["-"].meta, descriptionMsg: __("ui.libs.shortcut.native.zoom-out"), macOnly: true },
            {
                shortcut: Shortcuts["0"].ctrl,
                descriptionMsg: __("ui.libs.shortcut.native.zoom-reset"),
                windowsOnly: true,
            },
            { shortcut: Shortcuts["0"].meta, descriptionMsg: __("ui.libs.shortcut.native.zoom-reset"), macOnly: true },
            { shortcut: Shortcuts.f.ctrl, descriptionMsg: __("ui.libs.shortcut.native.search"), windowsOnly: true },
            { shortcut: Shortcuts.f.meta, descriptionMsg: __("ui.libs.shortcut.native.search"), macOnly: true },
            { shortcut: Shortcuts.F5.ctrl, descriptionMsg: __("ui.libs.shortcut.native.refresh"), windowsOnly: true },
            { shortcut: Shortcuts.r.meta.shift, descriptionMsg: __("ui.libs.shortcut.native.refresh"), macOnly: true },
            { shortcut: Shortcuts.p.ctrl, descriptionMsg: __("ui.libs.shortcut.native.print"), windowsOnly: true },
            { shortcut: Shortcuts.p.meta, descriptionMsg: __("ui.libs.shortcut.native.print"), macOnly: true },
            { shortcut: Shortcuts.z.alt, descriptionMsg: __("ui.libs.shortcut.undo") },
            { shortcut: Shortcuts.s.alt, descriptionMsg: __("ui.libs.shortcut.save") },
        ],
    },
    {
        categoryMsg: __("ui.libs.shortcut.category.navigation"),
        shortcuts: [
            { shortcut: Shortcuts.h.alt, descriptionMsg: __("ui.libs.shortcut.open-launchpad") },
            {
                shortcut: Shortcuts["← / →"].alt,
                descriptionMsg: __("ui.libs.shortcut.native.navigate-back-forward"),
                windowsOnly: true,
            },
            {
                shortcut: Shortcuts["← / →"].meta,
                descriptionMsg: __("ui.libs.shortcut.native.navigate-back-forward"),
                macOnly: true,
            },
            { shortcut: Shortcuts["1"].alt, descriptionMsg: __("ui.libs.shortcut.toggle-page-selection") },
            { shortcut: Shortcuts["2"].alt, descriptionMsg: __("ui.libs.shortcut.toggle-module-selection") },
            { shortcut: Shortcuts["3"].alt, descriptionMsg: __("ui.libs.shortcut.toggle-location-selection") },
            { shortcut: Shortcuts["4"].alt, descriptionMsg: __("ui.libs.shortcut.toggle-country-selection") },
            { shortcut: Shortcuts["5"].alt, descriptionMsg: __("ui.libs.shortcut.toggle-user-menu") },
        ],
    },
    {
        categoryMsg: __("ui.libs.shortcut.category.tables"),
        shortcuts: [
            { shortcut: Shortcuts.n.alt, descriptionMsg: __("ui.libs.shortcut.table.create-new") },
            { shortcut: Shortcuts.Escape, descriptionMsg: __("ui.libs.shortcut.table.cancel-edit") },
            { shortcut: Shortcuts["↓ / ↑"], descriptionMsg: __("ui.libs.shortcut.table.move-focus-down-up") },
            { shortcut: Shortcuts["+"].alt, descriptionMsg: __("ui.libs.shortcut.table.submit") },
            { shortcut: Shortcuts["Numpad+"], descriptionMsg: __("ui.libs.shortcut.table.submit") },
            { shortcut: Shortcuts.Enter.alt, descriptionMsg: __("ui.libs.shortcut.table.next-edit") },
            { shortcut: Shortcuts.NumpadReturn, descriptionMsg: __("ui.libs.shortcut.table.next-edit") },
            { shortcut: Shortcuts.Space, descriptionMsg: __("ui.libs.shortcut.table.toggle-selection") },
            { shortcut: Shortcuts.e.alt, descriptionMsg: __("ui.libs.shortcut.table.edit") },
            { shortcut: Shortcuts.d.alt, descriptionMsg: __("ui.libs.shortcut.table.delete") },
        ],
    },
    {
        categoryMsg: __("ui.libs.shortcut.category.window-control"),
        shortcuts: [
            { shortcut: Shortcuts.w.ctrl, descriptionMsg: __("ui.libs.shortcut.native.close-tab"), windowsOnly: true },
            { shortcut: Shortcuts.w.meta, descriptionMsg: __("ui.libs.shortcut.native.close-tab"), macOnly: true },
            {
                shortcut: Shortcuts.F4.alt,
                descriptionMsg: __("ui.libs.shortcut.native.close-browser"),
                windowsOnly: true,
            },
            { shortcut: Shortcuts.q.meta, descriptionMsg: __("ui.libs.shortcut.native.close-browser"), macOnly: true },
            {
                shortcut: Shortcuts["↓ / ↑"].meta,
                descriptionMsg: __("ui.libs.shortcut.native.minimize-maximize"),
                windowsOnly: true,
            },
            { shortcut: Shortcuts.m.meta, descriptionMsg: __("ui.libs.shortcut.native.minimize"), macOnly: true },
            {
                shortcut: Shortcuts["← / →"].meta,
                descriptionMsg: __("ui.libs.shortcut.native.place-window-left-right"),
                windowsOnly: true,
            },
        ],
    },
];

const close = () => {
    SkeletonStore.showShortcutInfo = false;
};

const show = () => {
    SkeletonStore.showShortcutInfo = true;
};

export const ShortcutInfo = { show, close, list };
