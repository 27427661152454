import { computed } from "vue";
import { createIndexedDBStore } from "./indexed-db";

const INDEXEDDB_KEY = "FILE_SYSTEM_HANDLES";
const fileSystemHandleStore = createIndexedDBStore<Record<string, FileSystemDirectoryHandle | undefined>>(
    INDEXEDDB_KEY,
    // System tests mock the return value of `window.showDirectoryPicker` with an unserializable value
    { ignoreErrorsOnSet: typeof Cypress !== "undefined" },
);

const _reset = () => fileSystemHandleStore.delete();

// Feature detection. The API needs to be supported
// and the app not run in an iframe (cypress).
const isAPISupported = (type: "file" | "directory") =>
    (type === "file" ? "showSaveFilePicker" : "showDirectoryPicker") in window &&
    (() => {
        try {
            return window.self === window.top;
        } catch {
            return false;
        }
    })();

const _requestPermission = async (handle: FileSystemDirectoryHandle, mode: "read" | "readwrite") =>
    (await handle.queryPermission({ mode })) === "granted" || (await handle.requestPermission({ mode })) === "granted";

const requestFileHandle = async (filename: string, contentType: string, fileTypeSuffix: string) => {
    if (!isAPISupported("file")) return undefined;

    // remove potentially existing charset or other extra from contentType
    const mimeType = contentType.split(";")[0];
    try {
        return await showSaveFilePicker({
            suggestedName: `${filename}${fileTypeSuffix}`,
            types: [
                {
                    // description should be specified, otherwise the filepicker would fail to open in some cases
                    description: fileTypeSuffix.substring(1).toUpperCase(),
                    accept: { [mimeType as `${string}/${string}`]: [fileTypeSuffix as `.${string}`] },
                },
            ],
        });
    } catch (err) {
        // Report if the user has canceled the dialog.
        if ((err as Error).name === "AbortError") return "canceled";
        else throw err;
    }
};

const requestNewDirectoryHandle = async (directoryId: string, mode: "read" | "readwrite") => {
    if (!isAPISupported("directory")) return undefined;

    try {
        const newHandle = await showDirectoryPicker({ mode });
        fileSystemHandleStore.set({ [directoryId]: newHandle });
        return newHandle;
    } catch (err) {
        // Report if the user has canceled the dialog.
        if ((err as Error).name === "AbortError") return "canceled";
        else throw err;
    }
};

const requestDirectoryHandle = async (directoryId: string, mode: "read" | "readwrite") => {
    const handle = (await fileSystemHandleStore.get())?.[directoryId];
    return handle && (await Helpers._requestPermission(handle, mode))
        ? handle
        : requestNewDirectoryHandle(directoryId, mode);
};

const getDirectoryName = async (directoryId: string) => (await fileSystemHandleStore.get())?.[directoryId]?.name;

const useDirectoryName = (directoryId: string) => {
    const handles = fileSystemHandleStore.use();

    return computed(() => handles.value[directoryId]?.name);
};

export const FileSystemUtil = {
    requestFileHandle,
    requestNewDirectoryHandle,
    requestDirectoryHandle,
    getDirectoryName,
    useDirectoryName,
};

export const Helpers = {
    _requestPermission,
    _reset,
};
