/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { z } from "zod";
import { FeatureToggleSchema as FeatureTogglesFeatureToggleSchema } from "../feature_toggles/FeatureToggle";

export const ListFeatureTogglesResponseSchema = z.object({
    featureToggles: z.array(FeatureTogglesFeatureToggleSchema),
    nextPageToken: z.string(),
});

export type ListFeatureTogglesResponse = z.infer<typeof ListFeatureTogglesResponseSchema>;
