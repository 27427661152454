/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { z } from "zod";

export enum Status {
    STATUS_UNKNOWN = "STATUS_UNKNOWN",
    STATUS_DEFINED = "STATUS_DEFINED",
    STATUS_UNDEFINED = "STATUS_UNDEFINED",
}

export const StatusSchema = z.nativeEnum(Status).catch(Status.STATUS_UNKNOWN);
