<script lang="ts" setup>
import { __, DeadEndAction, WuxDeadEnd } from "@ui/components";
import { LOGIN_PATH } from "@ui/libs/auth";

const primary: DeadEndAction = {
    labelMsg: __("wam.entry.log-back-in"),
    // redirect param makes sure we end up on the launchpad and are not stuck in the entry UI.
    onClick: () => window.location.replace(LOGIN_PATH + "?redirect=%2F"),
};
</script>

<template>
    <WuxDeadEnd
        :titleMsg="__('wam.entry.logout-success.title')"
        :contentMsg="__('wam.entry.logout-success.description')"
        :primary="primary"
    />
</template>
