import { computed, onMounted, onUnmounted, ref } from "vue";

enum STATUS {
    ONLINE = "Online",
    OFFLINE = "Offline",
}

export const getDDNetworkStatus = () => {
    if (window.navigator.onLine) {
        return STATUS.ONLINE;
    }
    return STATUS.OFFLINE;
};

const onlineStatus = ref(window.navigator.onLine);
const use = () => computed(() => onlineStatus.value);

// register the network guard
export const useNetworkGuardActivation = () => {
    const isOnline = NetworkGuard.use();
    const updateOnlineStatus = () => {
        onlineStatus.value = window.navigator.onLine;
    };

    onMounted(() => {
        window.addEventListener("online", updateOnlineStatus);
        window.addEventListener("offline", updateOnlineStatus);
    });

    onUnmounted(() => {
        window.removeEventListener("online", updateOnlineStatus);
        window.removeEventListener("offline", updateOnlineStatus);
    });
    return isOnline;
};

export const NetworkGuard = { use, getDDNetworkStatus };
