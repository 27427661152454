<script lang="ts" setup>
import { __, MessageOrId } from "../../i18n";
import WuxDeadEnd, { DeadEndAction } from "../WuxDeadEnd/WuxDeadEnd.vue";

const props = defineProps<{
    titleMsg: MessageOrId;
    contentMsg: MessageOrId;
    footNoteMsg?: MessageOrId;
    secondary?: DeadEndAction;
}>();

const primary: DeadEndAction = {
    labelMsg: __("ui.components.error-page.back-to-overview"),
    onClick: () => window.location.assign("/"),
};
</script>

<template>
    <WuxDeadEnd
        :titleMsg="props.titleMsg"
        :contentMsg="props.contentMsg"
        :footNoteMsg="props.footNoteMsg"
        :primary="primary"
        :secondary="props.secondary"
    />
</template>
