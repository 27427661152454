import { RouteRecordRaw } from "vue-router";
import { configForRouteName, RouteConfig } from "../skeleton/routing";
import LocationSelectorPage from "./LocationSelectorPage.vue";

export const LOCATION_SELECTOR_NAME = "location-selection";

const locationSelectorRoute: RouteRecordRaw = {
    path: "/",
    name: LOCATION_SELECTOR_NAME,
    component: LocationSelectorPage,
};

/**
 * withLocationHandling adds a location parameter to all available routes. A location selector is added to the base path.
 * @param {RouteRecordRaw[]} routes - routes that are to be extended
 * @returns {{RouteRecordRaw[]}} - routes with location handling and a location selector in the base path
 */
export const withLocationHandling = (routes: RouteRecordRaw[], routeConfig: RouteConfig): RouteRecordRaw[] => [
    locationSelectorRoute,
    ...routes.map((route) =>
        configForRouteName(route.name, routeConfig)?.noLocation
            ? route
            : { ...route, path: `/:location(WH[0-9]{3}|HQ[0-9]{3})${route.path}` },
    ),
];
