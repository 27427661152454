/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { z } from "zod";
import { SectionSchema } from "./Section";

export const LocationSettingSchema = z.object({
    country: z.string(),
    section: SectionSchema,
    name: z.string(),
    location: z.string(),
    value: z.string(),
    reason: z.string(),
});

export type LocationSetting = z.infer<typeof LocationSettingSchema>;
