// even though we are not using DD locally, the code is included anyway as the library is not side effect free
import { datadogRum, RumEvent } from "@datadog/browser-rum";
// In order to use the getDDActionName functionality from the skeleton
// We don't want to export the getDDActionName function from the component library
// eslint-disable-next-line no-restricted-imports
import { DatadogActions as ComponentsDatadogActions } from "@ui/components/src/utils";
import { WaWiLocation } from "../location/location";
import { STAGE } from "../utils/stage";
import { getDDNetworkStatus } from "./NetworkGuard";

declare global {
    // provided by `ui/config/vite.config.ts`
    const __DD_VERSION__: string;
}

export type DD_ENV = "dev" | "prd" | "qas" | "tst";

// Enable dd on production environment only when running in headless mode
// To enable it also in headed mode, just remove the second condition
const isDDEnabled = () => import.meta.env.PROD && (typeof Cypress === "undefined" || Cypress.browser.isHeadless);
export const ACTION_PREFIX = "dd.action-name.";

const reportError = (err: Error) => {
    if (isDDEnabled()) {
        // https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#collect-errors-manually
        datadogRum.addError(err, {
            network: getDDNetworkStatus(), // Include network status in the error object
        });
    }
};

const getSessionID = () => {
    if (isDDEnabled()) {
        return datadogRum.getInternalContext()?.session_id;
    } else {
        return "local";
    }
};

/**
 * This function generates a prefixed action name for a given "ddName" (data dictionary name) or "MessageOrId" object.
 * The prefix "dd.action-name" is significant as it indicates actions already configured by the teams.
 * If no "ddName" is provided, it returns an empty string.
 * Note: We send the translation key to DataDog, not the translated message, to maintain data privacy and consistency across multiple locales.
 */
export const _getActionName = (targetName: string): string => {
    if (targetName && !targetName?.startsWith(ACTION_PREFIX)) {
        return `${ACTION_PREFIX}masked`;
    }
    return targetName;
};

export const _getModuleName = (url: string): string | undefined => {
    // RUM SDK supports custom view names, which can be used to override url_path_group.
    // If not specified, it will have the same content of view.url_path_group.
    // For WaWi the view.url_path_group only contains the module name which does not cover all cases
    // event.view.url contains the full path of the module
    // e.g: https://dev.pt.wawi.lidl/module/grc/wh091/GRC001?receivingDocumentId=321 -> /grc/?/GRC001
    // remove puery parameters
    const basePath = url.split("?")[0];
    // Remove the domain
    let viewName = basePath.includes("/module") ? basePath.split("/module").at(-1) : undefined;
    // remove location from the path
    const currentLocation = WaWiLocation.get();
    if (viewName && currentLocation) {
        viewName = viewName.replace(currentLocation.toLowerCase(), "?");
    }
    return viewName;
};
export const _getSessionReplaySampleRate = (env: DD_ENV, isTest: boolean) => {
    if (isTest) return 0;
    if (env === "dev" || env === "tst") return 100;
    // disable on prod and qas
    return 0;
};
export const _getPrivacyLevel = (env: DD_ENV) => {
    if (env === "dev") return "allow";
    return "mask";
};

const init = () => {
    if (isDDEnabled()) {
        const getEnv = (): DD_ENV => {
            const mapping: Record<NonNullable<typeof STAGE>, DD_ENV> = {
                DEV: "dev",
                TST: "tst",
                QAS: "qas",
            };
            return STAGE ? mapping[STAGE] : "prd";
        };
        const env = getEnv();
        const isTest = navigator.userAgent.includes("Cypress");

        datadogRum.init({
            applicationId: "04633585-8f14-4e02-a494-f081bc78f6e2",
            clientToken: "pub330aa509a9fe4abe3cddc06d5edfa802",
            site: "datadoghq.eu",
            service: "wawi",
            env,
            version: __DD_VERSION__,
            actionNameAttribute: "data-dd-action",
            sessionSampleRate: 100,
            sessionReplaySampleRate: _getSessionReplaySampleRate(env, isTest),
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: _getPrivacyLevel(env),
            beforeSend: (event: RumEvent) => {
                // We take measures to safeguard the data by restricting the transmission of plain text to Datadog.
                if (event.type === "action" && event.action.target) {
                    event.action.target.name = _getActionName(event.action.target.name);
                }

                // errors are already send to the error tracking and the logs should be filtered
                if (event.type === "error" && event.error.source === "console") return false;

                // if we can't form the right view_name from the url, we fallback to the default view_name
                event.view.name = _getModuleName(event.view.url) || event.view.name;
                return true;
            },
            allowedTracingUrls: [
                { match: RegExp("https://([^./]*\\.){1,2}wawi\\.lidl/api"), propagatorTypes: ["b3multi"] },
            ],
        });
    }
};

const addCustomAction = <T extends string>(
    name: T extends `${typeof ACTION_PREFIX}${string}` ? never : T,
    options?: { [key: string]: unknown },
) => {
    if (isDDEnabled()) {
        datadogRum.addAction(`${ACTION_PREFIX}${name}`, options);
    }
};

export const Datadog = { init, getSessionID };
export const DatadogActions = { track: addCustomAction, getName: ComponentsDatadogActions.getName, reportError };
