import { computed, watch } from "vue";
import { useUserStore } from "../auth/user";
import { WaWiCountry } from "../country/country";
import { getModuleIsActiveFor, useActiveLocations, useCurrentModule } from "../module/module";
import { WaWiPreview } from "../preview/preview";
import { LOG } from "../skeleton/errors";
import { useLocationsStore } from "./store";

const WA_WI_LOCATION_REGEX = /^(HQ|WH)(\d{3})$/;
export const LOCATION_KEY = "wawi-location";

export enum LocationType {
    ALL = "ALL",
    HQ = "HQ",
    WH = "WH",
}

/**
 * setCurrentLocation pushes the given wa-wi location into the location parameter of the route and redirects to the root page
 * @param {string} loc - location to be set
 */
const setCurrentLocation = (loc: string) => {
    const locationUrl = getUrl(loc);
    if (locationUrl) {
        window.location.assign(locationUrl);
    } else {
        LOG.errorMsg(loc + " is not a valid location");
    }
};

const getUrl = (loc: string): string => {
    if (!WA_WI_LOCATION_REGEX.test(loc)) return "";
    const { moduleBase, routePath } = getPathInfo();
    // TODO(WAM): Deal with missing authorization.
    return `${moduleBase}/${loc.toLowerCase()}${routePath}`;
};

/**
 * saveLocation stores the given location in localStorage with key LOCATION_KEY
 * @param {string} loc - location to be saved
 */
const saveLocation = (loc: string) => {
    if (!WA_WI_LOCATION_REGEX.test(loc)) {
        LOG.errorMsg(loc + " is not a valid location");
        return;
    }

    localStorage.setItem(LOCATION_KEY, loc);
};

// extracts from a current pathname the module, location and rest of the app route
// "/foo/" -> { module: undefined; loc: undefined; routePath: "/foo/" }
// "/module/foo/bar" -> { module: "foo"; loc: undefined; routePath: "/bar" }
// "/module/foo/wh123/bar" -> { module: "foo"; loc: "wh123"; routePath: "/bar" }
const getPathInfo = (): { moduleBase: string; loc?: string; routePath: string } => {
    const fullPath = location.pathname + location.search + location.hash;

    const locationMatch = /^(\/([^/]+)\/([^/]+))(\/((hq|wh)\d{3}))?(\/.*)?/.exec(fullPath);
    if (!locationMatch) {
        return { moduleBase: "", routePath: fullPath };
    }

    const moduleBase: string = locationMatch[1];
    const loc: string | undefined = locationMatch[5];
    const routePath: string = locationMatch[7] || "/";

    return { moduleBase, loc, routePath };
};

/**
 * getLocation returns the wa-wi location of the current path
 * @returns {string} current wa-wi location
 */
const getCurrentLocation = (): string | undefined => getPathInfo().loc?.toUpperCase();

/**
 * getSavedLocation returns the location saved in the localStorage
 * @returns {string} saved wa-wi location
 */
const getSavedLocation = (): string | undefined => {
    const savedLocation = localStorage.getItem(LOCATION_KEY) ?? "";
    return WA_WI_LOCATION_REGEX.test(savedLocation) ? savedLocation : undefined;
};

// returns location number without leading 0
const getCurrentLocationNumber = (): string | undefined => {
    const current = getCurrentLocation();
    if (!current) {
        return undefined;
    }
    const value = WA_WI_LOCATION_REGEX.exec(current)?.at(2);
    // if value exists, remove padding
    return value && parseInt(value).toString();
};

// returns whether a location is a head quarter
const isHeadquarter = (): boolean => {
    const current = getCurrentLocation();
    if (!current) {
        return false;
    }
    return WA_WI_LOCATION_REGEX.exec(current)?.at(1) === "HQ";
};

// returns whether a location is a warehouse
const isWarehouse = (): boolean => {
    const current = getCurrentLocation();
    if (!current) {
        return false;
    }
    return WA_WI_LOCATION_REGEX.exec(current)?.at(1) === "WH";
};

// The value doesn't change during the runtime of the application, sufficient to get it once.
const wawiCountry = WaWiCountry.get();

export const createLocationHeaders = () => {
    const headers: HeadersInit = { "wawi-country": wawiCountry };
    const location = WaWiLocation.get();
    if (location) {
        headers["wawi-location"] = location;
    }
    return headers;
};

export const useFilteredLocations = () =>
    computed(() => {
        const locationStore = useLocationsStore();
        if (WaWiPreview.IS_PREVIEW_MODE) {
            return locationStore.locations;
        }

        const country = WaWiCountry.get();
        const activeLocations = useActiveLocations(country);
        const userLocations = Object.values(useUserStore().locationsByModule).flat();
        return locationStore.locations.filter((loc) => {
            if (!userLocations.includes(loc.id)) {
                return false;
            }
            const currentModule = useCurrentModule();
            if (!currentModule.value) {
                return activeLocations.value.has(loc.id);
            }
            return getModuleIsActiveFor(currentModule.value, country, loc.id);
        });
    });

// When there is only one location to choose from this composable will try to
// set it and save it but will not override previously saved locations.
const useSavedLocation = () => {
    const filteredLocations = useFilteredLocations();
    const getLocationIds = () => filteredLocations.value.map((loc) => loc.id);

    updateToSavedLocation(getLocationIds());
    watch(getLocationIds, updateToSavedLocation);
};

const updateToSavedLocation = (locations: string[]) => {
    if (WaWiLocation.get()) {
        return;
    }

    const savedLocation = WaWiLocation.getSavedLocation();
    if (savedLocation) {
        const isSavedLocationAvailable = locations.find((location) => location === savedLocation);

        if (isSavedLocationAvailable) {
            WaWiLocation.set(savedLocation);
            return;
        }
    }

    if (locations.length === 1) {
        const onlyLocation = locations[0];

        // If for example the user is given a link to a module which:
        // a) Is incompatible with the stored location and
        // b) Has only one available location
        // The composable will set the only possible location but not save it.
        if (!savedLocation) {
            WaWiLocation.saveLocation(onlyLocation);
        }

        WaWiLocation.set(onlyLocation);
    }
};

export const WaWiLocation = {
    set: setCurrentLocation,
    get: getCurrentLocation,
    getNumber: getCurrentLocationNumber,
    useSavedLocation,
    updateLocation: updateToSavedLocation,
    saveLocation,
    getSavedLocation,
    isHeadquarter,
    isWarehouse,
    getUrl,
};
