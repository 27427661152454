/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { z } from "zod";

export const CountryActiveLocationsSchema = z.object({
    country: z.string(),
    locations: z.array(z.string()),
});

export type CountryActiveLocations = z.infer<typeof CountryActiveLocationsSchema>;
