import { __ } from "@ui/components";
import { DatadogActions } from "./datadog";
import { Dialog } from "./Dialog";

const withPrefix = (msg: string, prefix?: string): string => (prefix ? `${prefix}: ${msg}` : msg);

const error = (error: Error, prefix?: string) => {
    // we don't log the error to the console in tests if it was handled via the LOG interface
    if (import.meta.env.MODE === "test") return;
    // for logging we create a "fresh" error to avoid errors that could happen if you try to re-assign the "message"
    // property of some special error classes. E.g. TypeError. Very important is the stack, but this is easy to copy.
    const newErrorMsg = withPrefix(error.message, prefix);
    const err = new Error(newErrorMsg);
    err.stack = error.stack?.replace(error.message, newErrorMsg);

    // eslint-disable-next-line no-console
    console.error(err);
    DatadogActions.reportError(err);
};

const errorMsg = (msg: string) => LOG.error(new Error(msg));

// can be used in "catch" clauses because technically everything could be thrown
const caught = (err: unknown, prefix?: string) =>
    err instanceof Error ? LOG.error(err, prefix) : LOG.errorMsg(withPrefix(`Caught non error: ${err}`, prefix));

export const LOG = { error, errorMsg, caught };

const showUnhandledError = () =>
    Dialog.showError({
        headerMsg: __("ui.libs.errors.unhandled.header"),
        contentMsg: __("ui.libs.errors.unhandled.content"),
    });

// This init function has to be called by each app.
// Every error processed by this mechanism prefixes the error message with "UNHANDLED" as indicator that these kind
// of issues should be fixed with very high priority.
export const initGlobalErrorHandling = () => {
    window.onunhandledrejection = (event: PromiseRejectionEvent) => {
        // we're already handling the error further down, so the default can be prevented
        event.preventDefault();
        // the promise was rejected with an error containing a valuable stack trace
        event.promise.catch((error) => {
            LOG.caught(error, "UNHANDLED PROMISE REJECTION");
            showUnhandledError();
        });
    };

    window.onerror = (_message, _source, _lineno, _colno, err) => {
        LOG.caught(err, "UNHANDLED ERROR");
        showUnhandledError();
        // Prevent further handling: https://developer.mozilla.org/en-US/docs/Web/API/Window/error_event#usage_notes
        return true;
    };

    // should be assigned to "app.config.errorHandler" to handle uncaught Vue errors
    return (err: unknown) => {
        LOG.caught(err, "UNHANDLED COMPONENT ERROR");
        showUnhandledError();
    };
};
