/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { z } from "zod";
import { LocationSettingSchema as SettingsLocationSettingSchema } from "../settings/LocationSetting";

export const ListSettingsResponseSchema = z.object({
    settings: z.array(SettingsLocationSettingSchema),
    nextPageToken: z.string(),
});

export type ListSettingsResponse = z.infer<typeof ListSettingsResponseSchema>;
