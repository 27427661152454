<script lang="ts" setup>
import { computed } from "vue";
import { useMsgFormatter } from "../../i18n";
import { Icon, Icons } from "../../icons";
import WuxIcon from "../WuxIcon/WuxIcon.vue";
import { WuxTileProps, WuxTileStatus } from "./WuxTile.core";

const iconStatusMap: Record<WuxTileStatus, { src: Icon; color?: string }> = {
    [WuxTileStatus.SUCCESS]: { src: Icons.circle_check, color: "var(--wawi-color-green-500)" },
    [WuxTileStatus.WARNING]: { src: Icons.triangle_warning, color: "var(--wawi-color-orange-500)" },
    [WuxTileStatus.INFO]: { src: Icons.circle_information, color: "var(--wawi-color-primary-500)" },
    [WuxTileStatus.ERROR]: { src: Icons.circle_x, color: "var(--wawi-color-red-500)" },
};

const icon = computed<{ src: Icon; color?: string } | undefined>(() =>
    props.status ? iconStatusMap[props.status] : undefined,
);

const classNameStatus = computed<string>(() => (props.status ? "wux-tile--" + props.status : ""));

const props = defineProps<WuxTileProps>();
const { optM } = useMsgFormatter();
</script>

<template>
    <section class="wux-tile" :class="[classNameStatus, { 'wux-tile--arrow': showArrow }]">
        <div v-if="props.titleMsg" class="wux-tile__header">
            <WuxIcon v-if="icon" :src="icon.src" :size="48" :color="icon.color" />
            <h2>{{ optM(props.titleMsg) }}</h2>
        </div>
        <div class="wux-tile__content">
            <slot />
        </div>
        <!-- loading indicator -->
        <div v-if="props.isLoading" class="wux-tile__loading" />
    </section>
</template>

<style lang="scss">
@use "../../../assets/styles/mixins.scss";
.wux-tile {
    --wux-tile-padding: 1rem;
    --wux-tile-content-height: auto;
    --wux-tile-outline: none;
    --wux-tile-arrow-shadow: transparent 0 0 0;
    border-radius: var(--wux-tile-border-radius, 0);
    position: relative;
    break-inside: avoid-column;
    background-color: var(--wawi-color-grayscale-white);
    box-shadow:
        rgba(0, 0, 0, 0.1) 1px 1px 4px 1px,
        var(--wawi-color-neutral-100) 0 0 0 2px;
    outline: var(--wux-tile-outline);
    &--warning {
        --wux-tile-outline: 1px solid var(--wawi-color-orange-500);
        --wux-tile-arrow-shadow: var(--wawi-color-orange-500) 1px 1px 0px;
    }
    &--error {
        --wux-tile-outline: 1px solid var(--wawi-color-red-500);
        --wux-tile-arrow-shadow: var(--wawi-color-red-500) 1px 1px 0px;
    }
    &--info {
        --wux-tile-outline: 1px solid var(--wawi-color-primary-500);
        --wux-tile-arrow-shadow: var(--wawi-color-primary-500) 1px 1px 0px;
    }
    &--success {
        --wux-tile-outline: 1px solid var(--wawi-color-green-500);
        --wux-tile-arrow-shadow: var(--wawi-color-green-500) 1px 1px 0px;
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: var(--wux-tile-padding);
        padding-bottom: 0;
    }

    &__content {
        box-sizing: border-box;
        padding: var(--wux-tile-padding);
        height: var(--wux-tile-content-height);
    }

    &--arrow:after {
        position: absolute;
        content: "";
        bottom: -8px;
        left: calc(50% - 8px);
        transform: rotate(45deg);
        border-width: 8px;
        border-style: solid;
        border-top-color: transparent;
        border-left-color: transparent;
        border-image: initial;
        border-right-color: var(--wawi-color-grayscale-white);
        border-bottom-color: var(--wawi-color-grayscale-white);
        box-shadow:
            rgba(0, 0, 0, 0.1) 2px 2px 3px 0px,
            var(--wux-tile-arrow-shadow);
    }

    // loader
    &__loading {
        @include mixins.topLoadingBar();
    }

    &:has(.wux-bottom-bar) {
        --wux-bottom-bar-position: absolute;
        z-index: 0; // This is important to override the bottom bar z-index inside the tile
        .wux-tile__content {
            padding-bottom: calc(var(--wux-bottom-bar-height) + var(--wux-tile-padding));
        }
    }
}
</style>
