/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { z } from "zod";

export enum LocationType {
    LOCATION_TYPE_UNKNOWN = "LOCATION_TYPE_UNKNOWN",
    LOCATION_TYPE_HQ = "LOCATION_TYPE_HQ",
    LOCATION_TYPE_WH = "LOCATION_TYPE_WH",
}

export const LocationTypeSchema = z.nativeEnum(LocationType).catch(LocationType.LOCATION_TYPE_UNKNOWN);
