/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { z } from "zod";

export const ToggleActivationSchema = z.object({
    location: z.string(),
    isActive: z.boolean(),
});

export type ToggleActivation = z.infer<typeof ToggleActivationSchema>;
