/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { z } from "zod";
import { ModuleSchema as ModulesModuleSchema } from "../modules/Module";

export const ListModulesResponseSchema = z.object({
    modules: z.array(ModulesModuleSchema),
    nextPageToken: z.string(),
});

export type ListModulesResponse = z.infer<typeof ListModulesResponseSchema>;
