<script lang="ts" setup>
import { onMounted } from "vue";
import { __, useMsgFormatter } from "../../i18n";
import { Icons } from "../../icons";
import WuxButton from "../WuxButton/WuxButton.vue";
import { WuxToastElementEmits, WuxToastElementProps } from "./WuxToast.core";

// after 5 seconds the toast will automatically close
const TOAST_CLOSE_TIME = 5000;

const { optM, m } = useMsgFormatter();

const props = defineProps<WuxToastElementProps>();

const emit = defineEmits<WuxToastElementEmits>();

const onClick = () => emit("close");

onMounted(() => {
    setTimeout(() => emit("close"), TOAST_CLOSE_TIME);
});
</script>

<template>
    <div class="wux-toast-element">
        <div class="wux-toast-element__success" />
        <div class="wux-toast-element__message">
            <span class="wux-toast-element__message-header">{{
                m(props.headerMsg ?? "ui.components.toast.success")
            }}</span>
            <span v-if="props.contentMsg" class="wux-toast-element__message-content">{{ optM(props.contentMsg) }}</span>
        </div>
        <WuxButton
            class="wux-toast-element__close"
            :icon="Icons.x"
            variant="text"
            isDense
            @click="onClick"
            color="white"
        />
    </div>
</template>
<style lang="scss">
.wux-toast-element {
    backdrop-filter: blur(3px);
    background-color: var(--wawi-color-black-overlay-dark);
    color: var(--wawi-color-grayscale-white);
    font-size: 16px;
    display: flex;
    &__success {
        background-color: var(--wawi-color-green-500);
        width: 0.5rem;
    }
    &__message {
        flex: 1;
        display: flex;
        padding: 1rem 1rem 1rem 2rem;
        flex-direction: column;
        &-header {
            font-weight: 600;
        }
        &-content {
            margin-top: 0.5rem;
            font-weight: 300;
        }
    }
    &__close {
        align-self: flex-start;
        margin: 4px;
        color: var(--wawi-color-grayscale-white);
        &:not(:disabled):hover {
            background: var(--wawi-color-neutral-800);
            color: var(--wawi-color-grayscale-white);
        }
        &:not(:disabled):active {
            background: var(--wawi-color-neutral-900);
        }
    }
}
</style>
