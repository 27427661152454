import { reactive } from "vue";
import { RouteConfig } from "./routing";

// to prevent "flickering" from happening if multiple requests are fired in sequence.
const DEBOUNCE_END_TIME_MS = 200;

type SkeletonState = {
    isLoading: boolean;
    usesLocations: boolean;
    showShortcutInfo: boolean;
    routeConfig: RouteConfig | undefined;
    newVersionAvailable: boolean;
};

export const SkeletonStore = reactive<SkeletonState>({
    isLoading: false,
    usesLocations: false,
    showShortcutInfo: false,
    routeConfig: undefined,
    newVersionAvailable: false,
});

export const useSkeletonStore = (): Readonly<SkeletonState> => SkeletonStore;

let loadingCount = 0;
let timeout: ReturnType<typeof setTimeout>;

export const SkeletonStoreLoading = {
    start: () => {
        loadingCount++;
        SkeletonStore.isLoading = true;
        clearTimeout(timeout);
    },
    end: () => {
        loadingCount--;
        if (loadingCount === 0) {
            timeout = setTimeout(() => {
                SkeletonStore.isLoading = false;
            }, DEBOUNCE_END_TIME_MS);
        }
    },
};
