import { z } from "zod";
import { Rational } from "./rational";
import { Timestamp } from "./timestamp";

export const exists = (json: any, key: string) => {
    const value = json[key];
    return value !== null && value !== undefined;
};

export type Identity<T> = (json: any) => T;
export const identity = <T>(json: any): T => json;

export const mapValues = (data: any, fn: (item: any) => any) =>
    Object.fromEntries(Object.entries(data).map(([key, value]) => [key, fn(value)]));

export const RationalSchema = z
    .union([
        // handle regular rational message
        z.object({
            numerator: z.string(),
            denominator: z.string(),
        }),
        // handle rational proto message from decimal proto bytes
        z.object({
            numerator: z.string(),
        }),
        // handle decimal proto message
        z.object({
            value: z.string(),
        }),
    ])
    .transform((data, ctx) => {
        try {
            // handle decimal proto message
            if ("value" in data) {
                const r = Rational.toRat(data.value);
                if (!r) throw new Error(`Could not convert {"value":"${data.value}"} to Rational.`);
                return r;
            }
            // handle regular rational message
            if ("denominator" in data) return new Rational(BigInt(data.numerator), BigInt(data.denominator));

            // handle rational proto message from decimal proto bytes
            const r = Rational.toRat(data.numerator);
            if (!r) throw new Error(`Could not convert {"numerator":"${data.numerator}"} to Rational.`);
            return r;
        } catch (err) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: (err as Error).message,
            });
            return z.NEVER;
        }
    });

export enum HTTPMethod {
    // Note: We need to use uppercase here.
    GET = "GET",
    DELETE = "DELETE",
    POST = "POST",
    PUT = "PUT",
    PATCH = "PATCH",
}

export type Endpoint<RESPONSE, BODY, PATH_PARAMS, QUERY_PARAMS> = {
    method: HTTPMethod;
    path: PATH_PARAMS extends void ? string : (params: PATH_PARAMS) => string;
    parser: (json: any) => RESPONSE;
    __typeKeep?: BODY | QUERY_PARAMS; // this property will not be used, but is required for TypeScript
};

export const TimestampSchema = z
    .object({
        seconds: z.coerce.bigint(),
        nanos: z.coerce.number(),
    })
    .transform(({ seconds, nanos }) => new Timestamp(seconds, nanos));

// Google Types

export const GoogleAnySchema = z.object({ "@type": z.string() }).passthrough();
export type GoogleAny = z.infer<typeof GoogleAnySchema>;

export const GoogleDurationSchema = z.object({ seconds: z.coerce.bigint(), nanos: z.coerce.bigint() });
export type GoogleDuration = z.infer<typeof GoogleDurationSchema>;

export const GoogleFieldMaskSchema = z.string();
export type GoogleFieldMask = z.infer<typeof GoogleFieldMaskSchema>;

export const GoogleTimestampSchema = z.coerce.date();
export type GoogleTimestamp = z.infer<typeof GoogleTimestampSchema>;

export const GoogleValueSchema = z.object({}).passthrough();
export type GoogleValue = z.infer<typeof GoogleValueSchema>;

export const GoogleRpcStatusSchema = z.object({
    code: z.number(),
    message: z.string(),
    details: z.array(GoogleAnySchema),
});
export type GoogleRpcStatus = z.infer<typeof GoogleRpcStatusSchema>;

export const GoogleLROSchema = z.object({
    name: z.string(),
    metadata: GoogleAnySchema,
    done: z.boolean(),
    error: z.optional(GoogleRpcStatusSchema),
    response: z.optional(GoogleAnySchema),
});
export type GoogleLRO = z.infer<typeof GoogleLROSchema>;

export const GoogleDateSchema = z.object({ year: z.number(), month: z.number(), day: z.number() });
export type GoogleDate = z.infer<typeof GoogleDateSchema>;

export const GoogleTimeOfDaySchema = z.object({
    hours: z.number(),
    minutes: z.number(),
    seconds: z.number(),
    nanos: z.number(),
});
export type GoogleTimeOfDay = z.infer<typeof GoogleTimeOfDaySchema>;

export const GoogleLROListResponseSchema = z.object({
    operations: z.array(GoogleLROSchema),
    nextPageToken: z.string(),
});
export type GoogleLROListResponse = z.infer<typeof GoogleLROListResponseSchema>;

export const GoogleLROListRequestSchema = z.object({
    name: z.string(),
    filter: z.optional(z.string()),
    pageSize: z.optional(z.number()),
    pageToken: z.optional(z.string()),
});
export type GoogleLROListRequest = z.infer<typeof GoogleLROListRequestSchema>;

export const GoogleLROCancelRequestSchema = z.object({ name: z.string() });
export type GoogleLROCancelRequest = z.infer<typeof GoogleLROCancelRequestSchema>;

export const GoogleLROGetRequestSchema = z.object({ name: z.string() });
export type GoogleLROGetRequest = z.infer<typeof GoogleLROGetRequestSchema>;

export const GoogleEmptySchema = z.object({});
export type GoogleEmpty = z.infer<typeof GoogleEmptySchema>;

// utility types

type UnionToIntercetion<U> = (U extends any ? (arg: U) => any : never) extends (arg: infer I) => any ? I : never;

type DeepPickByPath<T, TPath extends string> = TPath extends `${infer A}.${infer B}`
    ? { [K in keyof T as K extends A ? K : never]: DeepPickByPath<Required<T[K]>, B> }
    : { [K in keyof T as K extends TPath ? K : never]: NonNullable<T[K]> };

// used for PathParams
export type DeepRequiredPick<T, TPathUnion extends string> = UnionToIntercetion<
    DeepPickByPath<Required<T>, TPathUnion>
>;

type GetTopLevel<TPaths> = TPaths extends `${infer A}.${string}` ? A : TPaths;
// Returning otherwise an empty string to pass the check `"" extends TNested` successfully in these cases
type GetTopLevelNested<TPaths> = TPaths extends `${infer A}.${string}` ? A : "";
type GetSubLevelNested<TPrefix extends string, TPaths> = TPaths extends `${TPrefix}.${infer B}` ? B : never;

// used for BodyParams
export type DeepOmit<T, PathUnion extends string> = undefined extends T
    ? undefined | DeepOmit<NonNullable<T>, PathUnion>
    : Omit<T, GetTopLevel<PathUnion>> &
          (GetTopLevelNested<PathUnion> extends `${infer TNested}`
              ? "" extends TNested
                  ? // only using {} in a intersection will be optimized away from TS output
                    // eslint-disable-next-line @typescript-eslint/ban-types
                    {}
                  : {
                        [K in keyof T & string as K extends TNested ? K : never]: DeepOmit<
                            T[K],
                            GetSubLevelNested<K, PathUnion>
                        >;
                    }
              : never);

type DeepPartial<T, TNonNull = NonNullable<T>> = TNonNull extends Array<any> | Rational | Timestamp
    ? T
    : // this []-sourounding is necessary to keep unions as separate unions
      [TNonNull] extends [object]
      ? { [K in keyof TNonNull]?: DeepPartial<TNonNull[K]> }
      : T;

// ATTENTION: Currently, unions inside of object don't get correctly inferred by zod
//            -> https://github.com/colinhacks/zod/issues/2334
//            This causes to allow to specify all properties as optional
// used for QueryParams
export type OmitOptionalDeep<T, PathUnion extends string> = DeepPartial<Omit<T, GetTopLevel<PathUnion>>> &
    (GetTopLevelNested<PathUnion> extends `${infer TNested}`
        ? "" extends TNested
            ? // only using {} in a intersection will be optimized away from TS output
              // eslint-disable-next-line @typescript-eslint/ban-types
              {}
            : {
                  [K in keyof T & string as K extends TNested ? K : never]?: OmitOptionalDeep<
                      T[K],
                      GetSubLevelNested<K, PathUnion>
                  >;
              }
        : never);
