/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { z } from "zod";
import { ToggleActivationSchema } from "./ToggleActivation";
import { ToggleStateSchema } from "./ToggleState";

export const FeatureToggleSchema = z.object({
    name: z.string(),
    country: z.string(),
    activations: z.array(ToggleActivationSchema),
    /**
     * we need to know when to use a "create" or an "update" in the frontend
     */
    state: ToggleStateSchema,
});

export type FeatureToggle = z.infer<typeof FeatureToggleSchema>;
