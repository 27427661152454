/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { type Endpoint, HTTPMethod, type OmitOptionalDeep } from "../../../src/runtime";
import { type ListModulesRequest } from "../configs/ListModulesRequest";
import { type ListModulesResponse, ListModulesResponseSchema } from "../configs/ListModulesResponse";

export type ListConfigsModulesQueryParams = OmitOptionalDeep<ListModulesRequest, "">;

export const ListConfigsModules: Endpoint<ListModulesResponse, void, void, ListConfigsModulesQueryParams> = {
    method: HTTPMethod.GET,
    path: "/wam/configs/modules",
    parser: ListModulesResponseSchema.parse,
};
