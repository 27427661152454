import { Fetch } from "./fetch";
import { Endpoint } from "./runtime";

type GenericListResponse = {
    nextPageToken?: string;
};

type GenericListBodyParams = {
    pageSize: bigint;
    pageToken: string;
};

export const fetchAllPages = <
    TResponse extends GenericListResponse,
    TEntry,
    TBodyParams extends GenericListBodyParams,
>({
    endpoint,
    extract,
    body,
    pageSize = 100n,
    limit = 2000n,
    signal,
}: {
    endpoint: Endpoint<TResponse, TBodyParams, void, void>;
    extract: (r: TResponse) => TEntry[];
    body: Omit<TBodyParams, "pageSize" | "pageToken">;
    pageSize?: bigint;
    limit?: bigint;
    signal?: AbortSignal;
}): Promise<TEntry[]> =>
    fetchPageRecursive({ endpoint, extract, pageSize, pageToken: "", alreadyFetchedData: [], body, limit, signal });

const fetchPageRecursive = <TResponse extends GenericListResponse, TEntry, TBodyParams extends GenericListBodyParams>({
    endpoint,
    extract,
    pageSize,
    pageToken,
    alreadyFetchedData,
    body,
    limit,
    signal,
}: {
    endpoint: Endpoint<TResponse, TBodyParams, void, void>;
    extract: (r: TResponse) => TEntry[];
    pageSize: bigint;
    pageToken: string;
    alreadyFetchedData: TEntry[];
    body: Omit<TBodyParams, "pageSize" | "pageToken">;
    limit: bigint;
    signal?: AbortSignal;
}): Promise<TEntry[]> =>
    Fetch.fetchParsedResponse({
        endpoint,
        body: { pageToken, pageSize, ...body },
        signal,
    }).then((response) => {
        alreadyFetchedData.push(...extract(response));
        if (response.nextPageToken && alreadyFetchedData.length < limit) {
            return fetchPageRecursive({
                endpoint,
                extract,
                pageSize,
                pageToken: response.nextPageToken,
                alreadyFetchedData,
                body,
                limit,
            });
        }
        return alreadyFetchedData;
    });
