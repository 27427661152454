<script lang="ts" setup>
import { computed } from "vue";
import { MessageOrId, useMsgFormatter } from "../../i18n";
import { Icon } from "../../icons";
import WuxIcon from "../WuxIcon/WuxIcon.vue";
import { WuxTabEmits } from "./WuxTabBar.core";

type Props = {
    id: string;
    /**
    Values of the 'status' property are based on the Wux color variables.
    If you update the values, make sure to update the SCSS below as well.
     */
    trailingIcon?: { icon: Icon; status?: "warning" | "error" | "success" | "info" };
    /**
     * Event emitted when a tab button is clicked.
     */
    onClick?: (event: MouseEvent) => void;
    /**
     * Disables the tab button.
     */
    isDisabled?: boolean;
    /**
     * It displays the tab as focused (used for the keyboard navigation)
     */
    isFocused?: boolean;
    /**
     * Set the selected state of the button.
     */
    isSelected?: boolean;
    /**
     * Message for tab label
     */
    labelMsg?: MessageOrId;
    /**
     * Text for tab label
     */
    label?: string;
};
const props = defineProps<Props>();

const { rawM } = useMsgFormatter();

const emit = defineEmits<WuxTabEmits>();

const label = computed(() => rawM(props.labelMsg, props.label));

const onClick = (e: MouseEvent) => {
    if (props.isDisabled) return;
    props.onClick?.(e);
    emit("update:modelValue", props.id);
};
</script>

<template>
    <div
        :tab="id"
        class="wux-tab__label"
        :class="[
            props.trailingIcon?.status && `wux-tab__status--${props.trailingIcon.status}`,
            props.isDisabled && 'wux-tab--disabled',
            props.isSelected && 'wux-tab--selected',
            props.isFocused && 'wux-tab--focused',
        ]"
        @click="onClick"
    >
        {{ label }}
        <WuxIcon v-if="props.trailingIcon" :src="props.trailingIcon.icon" :size="20" />
    </div>
</template>

<style lang="scss">
.wux-tab {
    &__label {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        user-select: none;
        font-size: 20px;
        padding: 0.625rem;
        scroll-snap-align: start;
        scroll-margin-left: 40px;
        &:hover {
            cursor: pointer;
            &.wux-tab--disabled {
                cursor: default;
            }
        }
    }

    &__status {
        // colors defined based on our wux color variables
        &--error {
            .wux-icon {
                color: var(--wawi-color-red-500);
            }
        }
        &--warning {
            .wux-icon {
                color: var(--wawi-color-orange-500);
            }
        }
        &--success {
            .wux-icon {
                color: var(--wawi-color-green-500);
            }
        }
        &--info {
            .wux-icon {
                color: var(--wawi-color-primary-500);
            }
        }
    }

    &--selected {
        color: var(--wawi-color-primary-500);
        box-shadow: inset 0 -4px 0 -1px var(--wawi-color-primary-500);
        transition: box-shadow 0.15s linear;
    }

    &--disabled {
        color: var(--wawi-color-neutral-500);
    }

    &:focus {
        overflow: unset;
    }

    &--focused {
        outline: var(--focus-visible-outline-width--regular) solid var(--wawi-color-primary-300);
        outline-offset: var(--focus-visible-outline-offset--regular);
    }
}
</style>
