// adopted from: https://github.com/CyrusOfEden/CSV.js/blob/master/csv.src.js
// useful CSV information: https://en.wikipedia.org/wiki/Comma-separated_values

const CELL_DELIMITERS = [",", ";", "\t", "|", "^"];
const LINE_DELIMITERS = ["\r\n", "\r", "\n"];

type InnerOpts = {
    delimiter: string;
    newline: string;
    headers: boolean | string[];
};

type Opts = InnerOpts & {
    skip: number;
    limit: false | number;
};

export type EncodeOpts = Opts;

const STANDARD_ENCODE_OPTS: Opts = {
    delimiter: CELL_DELIMITERS[0],
    newline: LINE_DELIMITERS[0],
    skip: 0,
    limit: false,
    headers: false,
};

export type DecodeOpts = Opts & {
    cast: boolean | ("number" | "boolean" | "string")[];
};

const STANDARD_DECODE_OPTS: DecodeOpts = {
    delimiter: CELL_DELIMITERS[0],
    newline: LINE_DELIMITERS[0],
    skip: 0,
    limit: false,
    headers: false,
    cast: false,
};

const encodeCells = (line: string[], delimiter: string, newline: string): string =>
    line
        .map((c) => {
            const cell = c.replace(/"/g, '""');
            return cell.includes(delimiter) || cell.includes(newline) || cell.includes(`""`) ? `"${cell}"` : cell;
        })
        .join(delimiter);

const encodeArrayRows = (rows: string[][], { delimiter, newline, headers }: InnerOpts): string[] => {
    const result = rows.map((row) => encodeCells(row, delimiter, newline));

    if (Array.isArray(headers)) result.unshift(encodeCells(headers, delimiter, newline));

    return result;
};

const encodeObjectRows = (rows: Record<string, string>[], { delimiter, newline, headers }: InnerOpts): string[] => {
    const headersByKeys: string[] = Object.keys(rows[0]);
    const result: string[] = rows.map((row) =>
        encodeCells(
            headersByKeys.map((key) => row[key]),
            delimiter,
            newline,
        ),
    );

    if (Array.isArray(headers)) {
        result.unshift(encodeCells(headers, delimiter, newline));
    } else if (headers) {
        result.unshift(encodeCells(headersByKeys, delimiter, newline));
    }

    return result;
};

// parses both quoted & unquoted CSV text
const safeParse = (text: string, { delimiter, newline }: InnerOpts): string[][] => {
    const rows: string[][] = [];
    let tokens = "";
    let rowIdx = 0;
    let tokenIdx = 0;
    while (text[tokenIdx]) {
        rows[rowIdx] ??= [];
        if (text[tokenIdx] === '"') {
            tokenIdx++;
            while (text[tokenIdx]) {
                const currentToken = text[tokenIdx];
                const nextToken = text[tokenIdx + 1];
                // escaped quote OR end of cell
                if (currentToken === '"') {
                    tokenIdx++;
                    // end of cell
                    if (nextToken !== '"') break;
                }
                tokenIdx++;
                tokens += currentToken;
            }
        }

        if (text[tokenIdx] === delimiter) {
            rows[rowIdx].push(tokens);
            tokenIdx++;
            tokens = "";
            continue;
        }

        if (text[tokenIdx] === newline[0]) {
            rows[rowIdx].push(tokens);
            tokenIdx = tokenIdx + newline.length;
            tokens = "";
            rowIdx++;
            continue;
        }

        tokens += text[tokenIdx] ?? "";
        tokenIdx++;
    }

    const hasTrailingEmptyCell = text.endsWith(delimiter);
    if (tokens.length || hasTrailingEmptyCell) {
        rows[rowIdx].push(tokens);
    }

    return rows;
};

const encode = (rows: Record<string, string>[] | string[][], opts?: Partial<EncodeOpts>): string => {
    const { skip, limit, ...innerOpts }: EncodeOpts = { ...STANDARD_ENCODE_OPTS, ...opts };
    if (!rows.length) return "";

    const lines = (Array.isArray(rows[0]) ? encodeArrayRows : encodeObjectRows)(
        rows.slice(skip, limit ? skip + limit : undefined) as any,
        innerOpts,
    );

    return lines.join(innerOpts.newline);
};
// In the original code, limit works differently in decoding, it actually does two things depending on the state.
// * IF the string has quotes, then the limit is just used to determine number of characters to loop over to get most delimiter & newline characters IF they are not passed, then use them.
//    I didn't implement this cause we can safely assume always that we have a correct newline & delimiter characters
// * The other use-case is the one similar to encoding, but that was applied only in unsafeParse function, which didn't really make sense to me. So, I implemented the limit for safeParse instead since unsafeParse (since also, we didn't implement unsafeParse).
// TODO: support custom array of headers + array of objects return type IF needed
const decode = (text: string, opts?: Partial<DecodeOpts>): string[][] => {
    const { skip, limit, ...innerOpts }: DecodeOpts = { ...STANDARD_DECODE_OPTS, ...opts };
    if (!text.trim().length) return [];
    // we intentionally don't use trim here to ensure that we don't remove any leading/trailing whitespaces
    return safeParse(text, innerOpts).slice(skip, limit ? skip + limit : undefined);
};

export const CSV = { encode, decode };
