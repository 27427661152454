export const getLanguageOptions = (): LanguageOption[] =>
    LanguageOptions.sort((a, b) => a.value.localeCompare(b.value));

export type LanguageOption = (typeof LanguageOptions)[number];

export type Language = LanguageOption["value"];

// LanguageOptions are now static values, later they will be provided from /api/languages.
const LanguageOptions = [
    {
        label: "българин",
        countryLabel: "българия",
        value: "bg-BG",
    },
    {
        label: "čeština",
        countryLabel: "Česká republika",
        value: "cs-CZ",
    },
    {
        label: "Deutsch",
        countryLabel: "International",
        value: "de-INT",
    },
    {
        label: "Deutsch",
        countryLabel: "Österreich",
        value: "de-AT",
    },
    {
        label: "Deutsch",
        countryLabel: "Schweiz",
        value: "de-CH",
    },
    {
        label: "Deutsch",
        countryLabel: "Deutschland",
        value: "de-DE",
    },
    {
        label: "Ελληνικά",
        countryLabel: "Ελλάδα",
        value: "el-GR",
    },
    {
        label: "English",
        countryLabel: "Great Britain",
        value: "en-GB",
    },
    {
        label: "English",
        countryLabel: "United States",
        value: "en-US",
    },
    {
        label: "Español",
        countryLabel: "España",
        value: "es-ES",
    },
    {
        label: "Français",
        countryLabel: "France",
        value: "fr-FR",
    },
    {
        label: "hrvatski",
        countryLabel: "Hrvatska",
        value: "hr-HR",
    },
    {
        label: "magyar",
        countryLabel: "Magyarország",
        value: "hu-HU",
    },
    {
        label: "Italiano",
        countryLabel: "Italia",
        value: "it-IT",
    },
    {
        label: "lietuvių",
        countryLabel: "Lietuva",
        value: "lt-LT",
    },
    {
        label: "polski",
        countryLabel: "Polska",
        value: "pl-PL",
    },
    {
        label: "Português",
        countryLabel: "Portugal",
        value: "pt-PT",
    },
    {
        label: "romana",
        countryLabel: "Romania",
        value: "ro-RO",
    },
    {
        label: "slovenčina",
        countryLabel: "Slovensko",
        value: "sk-SK",
    },
    {
        label: "Slovenščina",
        countryLabel: "Slovenija",
        value: "sl-SI",
    },
    {
        label: "српски",
        countryLabel: "Србија",
        value: "sr-RS",
    },
] as const satisfies {
    value: string;
    label: string;
    countryLabel: string;
}[];
