<template>
    <div class="wux-banner">
        <slot />
    </div>
</template>

<style lang="scss">
.wux-banner {
    padding-top: 8.5rem;

    &::before {
        content: "";
        background-size: cover;
        background-position: 0 40%;
        background-image: url("../../../assets/images/warehouse.webp");

        height: 8.5rem;
        position: absolute; // needs to be relative to the body
        z-index: -1;
        top: var(--wux-top-bar-height);
        left: 0;
        right: 0;
    }
}
</style>
