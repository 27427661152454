/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { z } from "zod";
import { CountryActiveLocationsSchema } from "./CountryActiveLocations";
import { DomainSchema } from "./Domain";
import { ScopeSchema } from "./Scope";
import { StatusSchema } from "./Status";

export const ModuleSchema = z.object({
    domain: DomainSchema,
    id: z.string(),
    scope: ScopeSchema,
    origin: z.string(),
    target: z.array(z.string()),
    activeLocations: z.array(CountryActiveLocationsSchema),
    status: StatusSchema,
    pilot: z.optional(z.boolean()),
});

export type Module = z.infer<typeof ModuleSchema>;
