import { type Component, markRaw, reactive } from "vue";
import { h } from "vue";
import { __, DialogType, MessageOrId, WuxDialogProps } from "@ui/components";
import { Datadog } from "./datadog";
import ErrorDialogContent from "./error-pages/ErrorDialogContent.vue";

export const DIALOG_FADE_OUT_TIME = 250;

type DialogContent =
    | { content: { component: Component }; contentMsg?: undefined }
    | { content?: undefined; contentMsg: MessageOrId };

type InfoDialogBaseProps = DialogContent & {
    headerMsg?: MessageOrId;
    closeMsg?: MessageOrId;
    onClose?: () => void;
};
type ErrorDialogBaseProps = DialogContent & {
    headerMsg?: MessageOrId;
    closeMsg?: MessageOrId;
    reloadMsg?: MessageOrId;
    onClose?: () => void;
    onReload?: () => void;
    showReloadButton?: boolean;
};

export type ErrorDialogState = Map<string, ErrorDialog>;

type ErrorDialog = ErrorDialogBaseProps & {
    numberOfOccurrences: number;
    timeOfOccurrence: number;
};

type ConfirmDialogProps = DialogContent & {
    headerMsg: MessageOrId;
    confirmMsg?: MessageOrId;
    cancelMsg?: MessageOrId;
    onConfirm: () => unknown;
    onCancel?: () => unknown;
} & ({ onReject: () => unknown; rejectMsg: MessageOrId } | { onReject?: undefined; rejectMsg?: undefined });

type DialogProps = WuxDialogProps & {
    onDismiss?: () => void;
    id: string;
};

type DialogState = {
    layers: DialogProps[];
};

const dialogStore = reactive<DialogState>({ layers: [] });
const errorsStore = reactive<ErrorDialogState>(new Map());

export const useDialogStore = (): Readonly<DialogState> => dialogStore;
export const _useErrorDialogStore = (): Readonly<ErrorDialogState> => errorsStore;

const close = (id: string) => () => {
    dialogStore.layers = dialogStore.layers.map((layer) => (layer.id === id ? { ...layer, isHidden: true } : layer));
    setTimeout(() => {
        dialogStore.layers = dialogStore.layers.filter((layer) => layer.id !== id);
    }, DIALOG_FADE_OUT_TIME);
};

const closeError = (id: string) => {
    errorsStore.delete(id);
};

// This is a utility funtion to generate an id from an objet
// We can't use crypto.createHash API since it is not accessable in the browser environment due to its externalization
// read more https://developer.mozilla.org/en-US/docs/Web/API/Web_Crypto_API
// For that we create a very simple hash method so no need to install a new library
const generateUniqueId = (jsonString: string): string => {
    // This variable will be used to accumulate the hash value calculated from the characters of the JSON string.
    let hash = 0;
    if (jsonString.length === 0) return hash.toString();
    for (let i = 0; i < jsonString.length; i++) {
        const char = jsonString.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0; // Convert to 32bit integer
    }
    return hash.toString();
};

// Keep track of all errors that occured in the UI
const __addNewError = (errorDialog: ErrorDialog, id: string) => {
    const previousOccurrences = errorsStore.get(id)?.numberOfOccurrences ?? 0;
    errorsStore.set(id, {
        ...errorDialog,
        timeOfOccurrence: Date.now(),
        numberOfOccurrences: previousOccurrences + 1,
    });
};

const showError = ({
    headerMsg = __("ui.libs.dialog-error-title"),
    closeMsg = __("ui.components.dialog.confirm"),
    reloadMsg = __("ui.components.dialog.reload"),
    content,
    onClose,
    showReloadButton = false,
    ...props
}: ErrorDialogBaseProps) => {
    const errorLayerIndex = dialogStore.layers.findIndex((layer) => layer.type === DialogType.ERROR);
    const errorLayerId = errorLayerIndex > -1 ? dialogStore.layers[errorLayerIndex].id : crypto.randomUUID();
    const _onClose = close(errorLayerId);
    const onCancel = () => {
        for (const error of errorsStore.values()) {
            error.onClose?.();
        }
    };

    const onReload = () => {
        onCancel(); // Trigger all onClose actions before reloading
        setTimeout(() => window.location.reload(), DIALOG_FADE_OUT_TIME);
    };

    // Update the error dialog layer
    const _updateErrorDialog = () => {
        const errorLayerIndex = dialogStore.layers.findIndex((layer) => layer.type === DialogType.ERROR);
        // if we already have error dialog we don't add a new layer, we simply update the content, otherwise we add a new layer
        if (errorLayerIndex > -1) {
            // New Error Content based on the errors occurred so far
            const errorContent = h(ErrorDialogContent, { errors: errorsStore });
            dialogStore.layers[errorLayerIndex] = {
                ...dialogStore.layers[errorLayerIndex],
                headerMsg: __("ui.libs.dialog-error-multiple-errors-title"), // overrride the header message
                contentMsg: undefined, // remove the content message if there is any
                content: markRaw({ component: errorContent }),
                primary: {
                    // if only one error is triggered, we simply show the same configured label message
                    labelMsg:
                        errorsStore.size > 1
                            ? __("ui.components.dialog.confirm-all")
                            : dialogStore.layers[errorLayerIndex].primary.labelMsg,
                    onClick: onCancel,
                },
                secondary: Array.from(errorsStore).some(([, error]) => error.showReloadButton)
                    ? [
                          {
                              // if only one error is triggered, we simply show the same configured reload message
                              labelMsg:
                                  errorsStore.size > 1
                                      ? __("ui.components.dialog.reload")
                                      : (dialogStore.layers[errorLayerIndex].secondary?.at(0)?.labelMsg ??
                                        __("ui.components.dialog.reload")),
                              onClick: onReload,
                          },
                      ]
                    : undefined,
            };
        } else
            dialogStore.layers.push({
                ...props,
                id: errorLayerId,
                content: content && markRaw(content),
                headerMsg,
                type: DialogType.ERROR,
                primary: { labelMsg: closeMsg, onClick: onCancel },
                footNoteMsg: {
                    id: __("ui.libs.dialog-error-info.session-id-hint"),
                    values: { session: Datadog.getSessionID() },
                },
                secondary: showReloadButton ? [{ labelMsg: reloadMsg, onClick: onReload }] : undefined,
            });
    };

    const errorDialog = {
        headerMsg,
        contentMsg: props.contentMsg,
        closeMsg,
        reloadMsg,
        onClose,
        onReload,
        showReloadButton,
        content: content && markRaw(content),
    } as ErrorDialog;
    // generate a unique error id based on the error data
    const errorId = generateUniqueId(
        JSON.stringify(errorDialog, (_key, value) => (typeof value === "bigint" ? value.toString() : value)),
    );
    const _onErrorClose = () => {
        closeError(errorId);
        onClose?.();
        _updateErrorDialog();
        if (errorsStore.size === 0) {
            // if no more errors, we close the whole layer
            _onClose();
        }
    };
    // Store the new error
    __addNewError({ ...errorDialog, onClose: _onErrorClose } as ErrorDialog, errorId);
    _updateErrorDialog();
};

const confirm = ({
    onConfirm,
    onCancel,
    onReject,
    content,
    confirmMsg = __("ui.components.dialog.confirm"),
    cancelMsg = __("ui.components.dialog.cancel"),
    rejectMsg,
    ...props
}: ConfirmDialogProps) => {
    const isTernary = !!(onReject && rejectMsg);
    const id = crypto.randomUUID();
    const onClose = close(id);
    const _onCancel = async () => {
        layer.secondary[0].isLoading = true;
        layer.primary.isDisabled = true;
        isTernary && (layer.secondary[1]!.isDisabled = true);
        try {
            // make sure this never fails as this will not cause the dialog to stay open
            await onCancel?.();
        } finally {
            layer.secondary[0].isLoading = false;
            layer.primary.isDisabled = false;
            isTernary && (layer.secondary[1]!.isDisabled = false);
            // always close in case of cancel because this should just revert the last users decision
            onClose();
        }
    };
    const onConfirmWrapped = async () => {
        layer.primary.isLoading = true;
        layer.secondary[0].isDisabled = true;
        isTernary && (layer.secondary[1]!.isDisabled = true);
        try {
            await onConfirm();
        } catch (err) {
            if (err === KEEP_OPEN_ERR) return;
            else throw err;
        } finally {
            layer.primary.isLoading = false;
            layer.secondary[0].isDisabled = false;
            isTernary && (layer.secondary[1]!.isDisabled = false);
        }
        // only closing the dialog in case no error occurred
        onClose();
    };
    const secondary: NonNullable<DialogProps["secondary"]> = [{ labelMsg: cancelMsg, onClick: _onCancel }];
    if (isTernary) {
        const _onReject = async () => {
            layer.secondary[1].isLoading = true;
            layer.primary.isDisabled = true;
            layer.secondary[0].isDisabled = true;
            try {
                await onReject();
            } finally {
                layer.secondary[1].isLoading = false;
                layer.primary.isDisabled = false;
                layer.secondary[0].isDisabled = false;
                // always close in case of reject because this should always
                // allow the user to continue with the current user decision
                onClose();
            }
        };
        secondary.push({ labelMsg: rejectMsg, onClick: _onReject });
    }
    const layer: DialogProps & Required<Pick<DialogProps, "secondary">> = reactive({
        ...props,
        content: content && markRaw(content),
        type: DialogType.CONFIRM,
        isDismissible: false,
        primary: { labelMsg: confirmMsg, onClick: onConfirmWrapped },
        secondary,
        onDismiss: _onCancel,
        id,
    });
    dialogStore.layers.push(layer);
};

const info = ({ content, onClose, closeMsg = __("ui.components.dialog.close"), ...props }: InfoDialogBaseProps) => {
    const id = crypto.randomUUID();
    const _onClose = close(id);
    const onCancel = () => {
        _onClose();
        onClose?.();
    };
    dialogStore.layers.push({
        ...props,
        content: content && markRaw(content),
        isDismissible: true,
        type: DialogType.INFO,
        primary: { labelMsg: closeMsg, onClick: onCancel },
        onDismiss: onCancel,
        id,
    });
};

const KEEP_OPEN_ERR = new Error("Control error: Dialog should stay open");

export const Dialog = { showError, confirm, info, KEEP_OPEN_ERR };
