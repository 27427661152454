<script lang="ts" setup>
import { useMsgFormatter } from "../../i18n";
import { Icons } from "../../icons";
import WuxIcon from "../WuxIcon/WuxIcon.vue";

const props = defineProps<{
    isOffline?: boolean;
}>();

const { m } = useMsgFormatter();
</script>
<template>
    <Transition name="wux-offline-indicator">
        <div class="wux-offline-indicator" v-show="props.isOffline">
            <div class="wux-offline-indicator__content">
                <WuxIcon :src="Icons.warning" color="var(--wawi-color-red-500)" />
                {{ m("ui.components.network-bar.message") }}
            </div>
        </div>
    </Transition>
</template>

<style lang="scss">
@use "../../../assets/styles/variables.scss";

.wux-offline-indicator {
    left: 0;
    right: 0;
    top: var(--wux-top-bar-height);
    pointer-events: none;
    $bg-color: var(--wawi-color-grayscale-white);
    $stripes-color: color-mix(in srgb, var(--wawi-color-red-500) 10%, white);
    background: repeating-linear-gradient(-45deg, $stripes-color, $stripes-color 3px, $bg-color 3px, $bg-color 15px);
    position: fixed;
    z-index: variables.z("offline-indicator");
    height: 40px;
    box-shadow: 1px 1px 6px 3px #00000033;

    &__content {
        padding-left: 1.875rem;
        padding-right: 1.25rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        gap: 0.75rem;
        color: var(--wawi-color-red-500);
    }

    &:after {
        content: " ";
        position: absolute;
        width: 0.5rem;
        background-color: var(--wawi-color-red-500);
        height: 100%;
        left: 0;
        top: 0;
    }
}
.wux-offline-indicator-enter-active,
.wux-offline-indicator-leave-active {
    transition: opacity 0.5s ease;
}

.wux-offline-indicator-enter-from,
.wux-offline-indicator-leave-to {
    opacity: 0;
}
</style>
