// Values copied from `check()` implementation of timestamp.pb.go
const minSeconds = -62135596800;
const maxSeconds = +253402300799;
const minNanos = 0;
const maxNanos = 1e9;

export class Timestamp {
    readonly seconds: bigint;
    readonly nanos: number;

    constructor(seconds: bigint, nanos: number) {
        if (seconds < minSeconds) throw new Error("Illegal timestamp instantiation, timestamp before 0001-01-01");
        if (seconds > maxSeconds) throw new Error("Illegal timestamp instantiation, timestamp after 9999-12-31");
        if (nanos < minNanos || nanos >= maxNanos)
            throw new Error("Illegal timestamp instantiation, nanos out-of-range");
        // normalize the stored values
        this.seconds = seconds;
        this.nanos = nanos;
    }

    equals(other: Timestamp): boolean {
        return this.seconds === other.seconds && this.nanos === other.nanos;
    }

    diff(other: Timestamp) {
        return Number(this.seconds) * maxNanos + this.nanos - Number(other.seconds) * maxNanos - other.nanos;
    }

    static fromDate(date: Date): Timestamp {
        if (isNaN(date.getTime())) {
            throw new Error("Invalid date cannot be parsed");
        }
        const seconds = BigInt(Math.floor(date.getTime() / 1000));
        // INFO: We dont get any nano precision from Date
        return new Timestamp(seconds, 0);
    }

    static toDate(ts: Timestamp): Date {
        const seconds = Number(ts.seconds);
        return new Date(seconds * 1000 + ts.nanos / 1e6);
    }
}
