import { inject, InjectionKey, isRef, onUnmounted, Plugin, Ref, watch } from "vue";

type ShortcutInfo = { code: string; alt?: boolean; ctrl?: boolean; shift?: boolean; meta?: boolean };

export type Shortcut<Mods = unknown> = {
    __: ShortcutInfo;
    toString: () => string;
    toJSON: () => string;
    // eslint-disable-next-line @typescript-eslint/ban-types
} & ("alt" extends Mods ? {} : { alt: Shortcut<Mods | "alt"> }) &
    // eslint-disable-next-line @typescript-eslint/ban-types
    ("ctrl" extends Mods ? {} : { ctrl: Shortcut<Mods | "ctrl"> }) &
    // eslint-disable-next-line @typescript-eslint/ban-types
    ("shift" extends Mods ? {} : { shift: Shortcut<Mods | "shift"> }) &
    // eslint-disable-next-line @typescript-eslint/ban-types
    ("meta" extends Mods ? {} : { meta: Shortcut<Mods | "meta"> });

const createShortcut = (key: string): Shortcut<never> => {
    const code = key.length === 1 ? (isNaN(+key) ? `Key${key.toUpperCase()}` : `Digit${key}`) : key;
    const shortcut: Pick<Shortcut<never>, "__" | "toString" | "toJSON"> = {
        __: { code },
        toString() {
            return serializeToKey(this.__);
        },
        toJSON() {
            return serializeToKey(this.__);
        },
    };

    // A proxy is used to execute functions if certain props of the shortcut are used, creating sort of a builder pattern
    const proxy = new Proxy(shortcut, {
        get(target, prop: keyof Shortcut<never>) {
            switch (prop) {
                case "__":
                case "toString":
                case "toJSON":
                    return target[prop];
                case "alt":
                    target.__.alt = true;
                    return proxy;
                case "ctrl":
                    target.__.ctrl = true;
                    return proxy;
                case "shift":
                    target.__.shift = true;
                    return proxy;
                case "meta":
                    target.__.meta = true;
                    return proxy;
                default:
                    return undefined;
            }
        },
    });
    return proxy as Shortcut<never>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Shortcuts: Record<string, Shortcut<never>> = new Proxy({} as any, {
    get(_target, prop) {
        if (typeof prop === "symbol") return undefined; // if used as symbol (e.g. object key)
        return createShortcut(prop);
    },
});

const withModifier = (shortcut: Shortcut, modifier: "alt" | "ctrl" | "shift" | "meta"): Shortcut => {
    const clone = {
        // we need to proceed like this instead of using the spread syntax, as othwerwise ctrl, alt, ... will all become true
        __: { ...shortcut.__ },
        toString: shortcut.toString,
        toJSON: shortcut.toJSON,
    };
    clone.__[modifier] = true;
    return clone;
};

const serializeToKey = ({ code, ctrl, alt, meta, shift }: ShortcutInfo): string =>
    [code, ctrl ? "c" : "", alt ? "a" : "", meta ? "m" : "", shift ? "s" : ""].join(",");

const serializeEvent = (e: KeyboardEvent): string =>
    serializeToKey({
        code: e.code,
        ctrl: e.ctrlKey,
        alt: e.altKey,
        shift: e.shiftKey,
        meta: e.metaKey,
    });

export type ShortcutHandler = {
    registerShortcut: (shortcut: Shortcut, handler: (e: KeyboardEvent) => void) => () => void;
    triggerShortcut: (e: KeyboardEvent) => void;
};
export const ShortcutHandlerInjectionKey = Symbol() as InjectionKey<ShortcutHandler>; // This injection key is only exposed for mocking inside vue-test-utils. Don't expose it to the modules.

/**
 * Creates a vue plugin to enable global shortcut handling.
 * Just call `app.use` with the return value of this function.
 * Afterwards you're able to register shortcuts in your app using the `ShortcutUtil.useGlobal` composable.
 *
 * Note: The returned plugin also exposes utilities to already register shortcuts from within main.ts even before the vue app is mounted
 *
 * @param options optional callbacks to use for sideffects like reporting usage of shortcuts to DataDog
 */
export const createShortcutHandler = (options?: {
    onTrigger?: (e: KeyboardEvent, shortcutName: string) => void;
    onRegister?: (shortcut: Shortcut) => void;
    onUnregister?: (shortcut: Shortcut) => void;
}): Plugin & ShortcutHandler => {
    const registeredShortcuts = new Map<string, Array<(e: KeyboardEvent) => void>>();

    const registerShortcut: ShortcutHandler["registerShortcut"] = (shortcut, handler) => {
        const key = shortcut.toString();
        if (!registeredShortcuts.has(key)) {
            registeredShortcuts.set(key, []);
        }

        options?.onRegister?.(shortcut);
        registeredShortcuts.get(key)!.push(handler);
        return () => {
            options?.onUnregister?.(shortcut);
            const handlers = registeredShortcuts.get(key)!.filter((h) => h !== handler);
            if (handlers.length) {
                registeredShortcuts.set(key, handlers);
            } else {
                registeredShortcuts.delete(key);
            }
        };
    };

    const triggerShortcut: ShortcutHandler["triggerShortcut"] = (e) => {
        const key = serializeEvent(e);
        const handlers = registeredShortcuts.get(key);
        if (!handlers) return;
        for (const handler of Array.from(handlers).reverse()) {
            handler(e);
            options?.onTrigger?.(e, key);
            if (e.defaultPrevented) break;
        }
    };

    return {
        // The install property is required for this object to be a valid vue plugin
        install: (app) => {
            app.provide(ShortcutHandlerInjectionKey, { registerShortcut, triggerShortcut });
            addEventListener("keydown", (e) => triggerShortcut(e));
        },
        registerShortcut,
        triggerShortcut,
    };
};

/**
 * Registers global shortcuts bound to the life-cycle of the component.
 *
 * @example
 * ```ts
 * ShortcutUtil.useGlobal([
 *   [Shortcuts.F12, (event) => { ... }],
 *   [toRef(props, "shortcut"), (event) => { ... }],
 * ]);
 * ```
 *
 * ATTENTION: You can register the same key multiple times and all handlers will be invoked in the reversed order of
 *            registration (or component nesting). As soon as some handler calls e.preventDefault() on the passed event
 *            the event will not be propagated to other handlers.
 */
const useGlobal = (
    ...handlers: [shortcut: Shortcut | Ref<Shortcut | undefined>, handler: (e: KeyboardEvent) => void][]
) => {
    if (!handlers.length) return;

    const shortcutHandler = inject(ShortcutHandlerInjectionKey);
    if (!shortcutHandler) {
        if (import.meta.env.DEV) {
            throw new Error(
                `No ShortcutHandler provided, unable to register the following global shortcuts: ${handlers.map(([shortcut]) => shortcut).join(", ")}. Please make sure to only call "useGlobalShortcut" inside Vue components or composables`,
            );
        }
        return;
    }

    for (const [shortcut, handler] of handlers) {
        let unregister: undefined | (() => void);
        onUnmounted(() => unregister?.());

        if (!isRef(shortcut)) {
            unregister = shortcutHandler.registerShortcut(shortcut, handler);
            continue;
        }

        // In case the shortcut is a reactive value we watch it to update it in case it changes
        watch(
            shortcut,
            (s) => {
                unregister?.();
                if (s !== undefined) {
                    unregister = shortcutHandler.registerShortcut(s, handler);
                } else {
                    unregister = undefined;
                }
            },
            { immediate: true },
        );
    }
};

/**
 * Can be used to create key down handlers on any kind of UI elements.
 *
 * In <script>:
 * const onKeyDown = ShortcutUtil.getKeyDownHandler(
 *   [Shortcuts.t.alt, () => { ... }],
 *   [Shortcuts.ArrowDown, () => { ... }]
 * );
 *
 * In <template>: @keydown="onKeyDown"
 */
const getKeyDownHandler = (...defs: [Shortcut, (e: KeyboardEvent) => void][]) => {
    // The special matchers are different because they can match multiple events (therefore we can't compare serializations)
    const specialDefs = defs.filter(([s]) => Object.keys(matchers).some((key) => s.__.code === key));
    const normalDefs = defs.filter(([s]) => !Object.keys(matchers).some((key) => s.__.code === key));
    const normalShortcuts = new Map(normalDefs.map(([s, h]) => [s.toString(), h]));
    return (e: KeyboardEvent) => {
        const key = serializeEvent(e);
        // Fallback to checking any special matchers
        // We use findLast instead of find to take into account the shortcuts overrides
        const shortcutFn = normalShortcuts.get(key) ?? specialDefs.findLast(([s]) => matches(s, e))?.[1];
        shortcutFn?.(e);
    };
};

/**
 * These are special matchers for certain keys that are tricky to match.
 * All matcher keys should be prefixed by "_" to avoid collisions with existing codes.
 */
const matchers: Record<string, (e: KeyboardEvent) => boolean> = {
    // on either Plus on the Numpad or Plus with Alt-Key
    // on macOS Alt & Plus produces "±"
    _Plus: (e: KeyboardEvent) => (e.altKey ? ["+", "±"].includes(e.key) : e.key === "+"),
};

const matches = (s: Shortcut, e: KeyboardEvent) => {
    const code = matchers[s.__.code]?.(e) ? s.__.code : e.code;
    // Attention: Destructuring of the event data via ...e may not work in all browsers as the properties are accessed
    //            on computed getters.
    const { metaKey, altKey, ctrlKey, shiftKey } = e;
    return serializeEvent({ metaKey, altKey, ctrlKey, shiftKey, code } as KeyboardEvent) === s.toString();
};

const equal = (s1: Shortcut, s2: Shortcut) => s1.toString() === s2.toString();

export const ShortcutUtil = {
    getKeyDownHandler,
    useGlobal,
    matches,
    equal,
    withModifier,
};
