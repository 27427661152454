const COUNTRY_KEY = "wawi-country";
const FALLBACK_COUNTRY_CODE = "PT";
const INTERNATIONAL_COUNTRY_CODE = "INT";
const INTERNATIONAL_COUNTRY_URL_PARAM = "global";
const SYSTEM_TEST_COUNTRY_CODE = "ST";

/**
 * Extract the country code from the current URL
 */
const getCountryFromURL = (): string => {
    const hostnameParts = window.location.hostname.split(".");
    const countryCodeIndex = hostnameParts.indexOf("wawi") - 1;
    if (countryCodeIndex >= 0) {
        const countryFromUrl = hostnameParts[countryCodeIndex];
        if (countryFromUrl === INTERNATIONAL_COUNTRY_URL_PARAM) {
            return INTERNATIONAL_COUNTRY_CODE;
        }
        // TODO (LP, WAM-R19Wß0NQ): Remove int fallback
        return hostnameParts[countryCodeIndex] === "int"
            ? (localStorage.getItem(COUNTRY_KEY) ?? FALLBACK_COUNTRY_CODE)
            : countryFromUrl.toUpperCase();
    }
    if (import.meta.env.DEV) {
        return localStorage.getItem(COUNTRY_KEY) ?? FALLBACK_COUNTRY_CODE;
    }
    throw Error("Failed to get country code: " + window.location.hostname);
};

/**
 * Generates a URL based on the current window location and the provided country code.
 */
const buildCountryUrl = (country: string): string => {
    const hostnameParts = window.location.hostname.split(".");
    const countryCodeIndex = hostnameParts.indexOf("wawi") - 1;
    if (countryCodeIndex >= 0) {
        hostnameParts[countryCodeIndex] =
            country.toLowerCase() === INTERNATIONAL_COUNTRY_CODE.toLowerCase()
                ? INTERNATIONAL_COUNTRY_URL_PARAM
                : country.toLowerCase();
    }
    return window.location.protocol + "//" + hostnameParts.join(".") + "/";
};

/**
 * Switching from int to country or country to int should always result in launchpad.
 */
const buildFinalUrl = (country: string, newCountryUrl: string): string =>
    country.toLowerCase() === INTERNATIONAL_COUNTRY_CODE.toLowerCase() || isInternational()
        ? newCountryUrl
        : newCountryUrl + window.location.pathname.split("/").slice(1, 3).join("/");

/**
 * Changes the country code directly in the URL, moving to a new page.
 */
const changeCountry = (country: string): void => {
    const newCountryUrl = buildCountryUrl(country);
    if (newCountryUrl === window.location.origin) {
        return;
    }
    window.location.assign(buildFinalUrl(country, newCountryUrl));
};

/**
 * Returns the full URL for the given country code. Used in <a> tags in the top bar menu.
 */
const getCountryUrl = (country: string): string => buildFinalUrl(country, buildCountryUrl(country));

const isInternational = (): boolean => getCountryFromURL() === INTERNATIONAL_COUNTRY_CODE;

const isSystemTestCountry = () => getCountryFromURL().toUpperCase() === SYSTEM_TEST_COUNTRY_CODE;

export const WaWiCountry = {
    get: getCountryFromURL,
    isInternational,
    isSystemTestCountry,
    change: changeCountry,
    getUrl: getCountryUrl,
};
