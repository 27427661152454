import { APIError } from "@ui/clients";
import { ServiceUtils } from "../skeleton";
import { LOG } from "../skeleton/errors";
import { getCurrentModuleScope } from "./guard";
import { REASON_REDIRECT, signal } from "./redirectController";
import { AuthService } from "./services/AuthService";
import {
    accessTokenKey,
    getLastRefreshTimestamp,
    parseJwt,
    setUserTokenAndExpirationDate,
    tokenExpirationDateKey,
    WAWI_REFRESH_TOKEN_LIFETIME,
} from "./user";

// random Jitter on Interval (0-15s), in order to prevent synchronicity events in case of backend downtimes
// or many open tabs. In case of a browser restart, all tabs are initialized at the "same" time, which could lead
// to request spikes.
const INTERVAL_WITH_JITTER = 1000 * 60 + Math.floor(Math.random() * 15000);
// period of time for which a token should be valid at least, from lower validity the token should be renewed (minutes * seconds * milliseconds)
const THREE_MINUTES = 3 * 60 * 1000;
const TEN_SECONDS = 10 * 1000;

let ongoingLogout: Promise<void> | null = null;
export const refreshTokenIfOld = async (threshold: number = THREE_MINUTES) => {
    if (!isAccessTokenExpiringIn(threshold)) {
        return;
    }
    // don't refresh if the refresh token is (almost) expired
    if (isRefreshTokenExpiringIn(TEN_SECONDS)) {
        if (ongoingLogout) return ongoingLogout;
        let resolveLogout: ((value: void) => void) | undefined;
        ongoingLogout = new Promise((resolve) => {
            resolveLogout = resolve;
        });
        try {
            await AuthService.onUnauthorized();
        } finally {
            ongoingLogout = null;
            resolveLogout?.();
        }
        return;
    }
    await refreshJwtToken();
};

export const refreshTokenIfInvalid = async () => {
    const token = sessionStorage.getItem(accessTokenKey);
    if (!token) {
        return;
    }

    const jwt = parseJwt(token);
    if (!jwt) {
        return;
    }

    const currentModuleScope = getCurrentModuleScope();
    const withinScope = jwt.modules?.some((module) => module === currentModuleScope);
    if (withinScope || jwt.sudo) {
        return refreshTokenIfOld();
    }

    let err = await refreshJwtToken();
    if (err?.code === 503 || err?.code === 504) {
        // try (only) once more
        err = await refreshJwtToken();
    }
    if (err) {
        ServiceUtils.catchError(err);
    }
};

/**
 * Here, an interval is created with which the validity of the currently available wawi token is checked once a minute (plus additional jitter).
 * If the validity period defined in the const THREE_MINUTES is not given, the token is renewed.
 */
export const scheduleAuthTokenRefresh = async () => {
    await refreshTokenIfInvalid();
    const intervalId = setInterval(refreshTokenIfOld, INTERVAL_WITH_JITTER);
    return () => clearInterval(intervalId);
};

export const isAccessTokenExpiringIn = (thresholdMs: number): boolean => {
    const dateString = sessionStorage.getItem(tokenExpirationDateKey);
    const expirationTimestamp = Number(dateString);

    if (dateString === null || isNaN(expirationTimestamp)) {
        return false;
    }
    const remainingMs = expirationTimestamp - Date.now();
    return remainingMs <= thresholdMs;
};

const isRefreshTokenExpiringIn = (thresholdMs: number): boolean => {
    const lastRefreshTimestamp = getLastRefreshTimestamp();
    return !!lastRefreshTimestamp && Date.now() - lastRefreshTimestamp > WAWI_REFRESH_TOKEN_LIFETIME - thresholdMs;
};

let ongoingRefresh: Promise<APIError | null> | null = null;
export const refreshJwtToken = async (): Promise<APIError | null> => {
    if (ongoingRefresh) return ongoingRefresh;
    let resolveRefresh: ((value: APIError | null) => void) | undefined;
    ongoingRefresh = new Promise((resolve) => {
        resolveRefresh = resolve;
    });
    let apiError: APIError | null = null;
    try {
        if (navigator.onLine) {
            const currentModuleScope = getCurrentModuleScope();
            const refreshResponse = await AuthService.refresh(currentModuleScope);
            setUserTokenAndExpirationDate(
                refreshResponse.access_token,
                refreshResponse.expires_at,
                refreshResponse.locationsByModule,
                refreshResponse.userCountries,
            );
        }
    } catch (e) {
        // Check if the request was aborted due to a redirect. If so, we handle it gracefully
        if (signal.aborted && signal.reason === REASON_REDIRECT) {
            return null;
        }
        if (e instanceof APIError) {
            apiError = e;
        }
        if (isAccessTokenExpiringIn(INTERVAL_WITH_JITTER)) {
            LOG.caught(e, "Failed to refresh auth token");
        }
    } finally {
        ongoingRefresh = null;
        resolveRefresh?.(apiError);
    }
    return apiError;
};
