/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { z } from "zod";
import { LocationSchema as LocationsLocationSchema } from "../locations/Location";

export const ListLocationsResponseSchema = z.object({
    locations: z.array(LocationsLocationSchema),
    nextPageToken: z.string(),
});

export type ListLocationsResponse = z.infer<typeof ListLocationsResponseSchema>;
