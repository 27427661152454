import { type Directive } from "vue";

/**
 * Custom directive to call a function whenever someone clicks outside the element the directive is placed on.
 * The element the directive is placed on will be passed to the callback as a parameter to e.g. check if it's visible.
 */
export const vOnOutsideClick: Directive<
    HTMLElement & { __removeOutsideClickListener?: () => void },
    (element: HTMLElement) => void | Promise<void>
> = {
    mounted(element, { value: callback }) {
        const outsideClickHandler = (event: MouseEvent) => {
            const isInsideElement = event.composedPath().includes(element);
            if (!isInsideElement) callback(element);
        };

        // mousedown is less ideal than click, but click has a bug with inputs
        // if you select the text inside an input with a mouse, and when you release the mouse you're outside the input field, an outside click will be triggered
        // mousedown always works, instead, since it will always be performed when you start selecting the text (i.e., inside the input)
        element.__removeOutsideClickListener = () => window.removeEventListener("mousedown", outsideClickHandler);
        window.addEventListener("mousedown", outsideClickHandler);
    },
    beforeUnmount(element) {
        element.__removeOutsideClickListener?.();
    },
};
