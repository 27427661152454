import { computed, onMounted, Ref, ref } from "vue";
import { del, get, set } from "idb-keyval";

type CreateIndexedDBStoreOptions = Partial<{
    ignoreErrorsOnSet: boolean; // Useful for silencing errors within system tests
}>;

export const createIndexedDBStore = <TStore extends Record<string, unknown | undefined>>(
    storeKey: IDBValidKey,
    options?: CreateIndexedDBStoreOptions,
) => {
    // Serializable version (also ensures things work even if the API isn't accessible)
    let _store: Partial<TStore> | undefined = undefined;
    // Reactive version (so that `use` works properly)
    const storeRef: Ref<Partial<TStore> | undefined> = ref(undefined);

    const fetchInitialStore = async () => {
        if (_store) return;
        storeRef.value = _store = await get(storeKey);
    };

    return {
        get: async (): Promise<Partial<TStore>> => {
            await fetchInitialStore();
            return storeRef.value ?? {};
        },
        use: () => {
            onMounted(fetchInitialStore);
            return computed<Partial<TStore>>(() => storeRef.value ?? {});
        },
        set: async (store: Partial<TStore>) => {
            storeRef.value = _store = { ..._store, ...store };
            try {
                return await set(storeKey, _store);
            } catch (err) {
                if (options?.ignoreErrorsOnSet) return;
                throw err;
            }
        },
        delete: () => {
            storeRef.value = _store = undefined;
            return del(storeKey);
        },
    };
};
