import { MessageOrId } from "../../i18n";

export enum WuxTileStatus {
    SUCCESS = "success",
    WARNING = "warning",
    INFO = "info",
    ERROR = "error",
}

export type WuxTileProps = {
    /**
     * (optional) headline of the tile
     */
    titleMsg?: MessageOrId;
    /**
     * (optional) little icon at the bottom of the tile, indicating that things done in this tile will have an effect on the content below.
     */
    showArrow?: boolean;
    /**
     * (optional) status (WARNING, ERROR, INFO, SUCCESS), will display a status icon and colored border
     */
    status?: WuxTileStatus;
    /**
     * Will be displayed the loading indicator.
     */
    isLoading?: boolean;
};
