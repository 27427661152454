import { useRouter } from "vue-router";
import { z } from "zod";
import { REASON_REDIRECT, signal } from "../auth/redirectController";
import { useUserStore } from "../auth/user";
import { LOG } from "./errors";
import { SkeletonStore } from "./store";

declare global {
    // provided by `ui/config/vite.config.ts`
    const __APP_VERSION_ID__: string;
}

const AvailableVersionSchema = z.object({ version: z.string() });

const getVersionTimestamp = (version: string): number => Number(version.split("-")[0]);

const _isNewerVersion = (currentVersion: string, availableVersion: string): boolean => {
    const availableVersionTimestamp = getVersionTimestamp(availableVersion);
    const currentVersionTimestamp = getVersionTimestamp(currentVersion);
    return availableVersionTimestamp > currentVersionTimestamp;
};

const currentVersion = __APP_VERSION_ID__;

// It can happen that e.g. a user that didn't focus the tap for a longer time might have invalid auth
// when focusing the tap then again, he will be force redirected to do the auth and the version-fetching
// could fail. In order to not create noise in our monitors for such a case, we keep track of the status.
const _AppVersionState = { lastFetchFailureStreak: 0, failureStreakThreshold: 3 };

const _fetchNewVersion = async (): Promise<string | undefined> => {
    try {
        const url = `${import.meta.env.BASE_URL}version.json`;

        const headers = {
            "Content-Type": "application/json",
        };

        const response = await fetch(url, {
            method: "GET",
            headers,
            signal,
        });
        if (!response.ok) {
            // Backend errors are already logged in the server side, so we don't log them here again.
            return undefined;
        }
        const remoteVersion = AvailableVersionSchema.parse(await response.json());
        const isNewVersion = _isNewerVersion(currentVersion, remoteVersion.version);
        _AppVersionState.lastFetchFailureStreak = 0;
        return isNewVersion ? remoteVersion.version : undefined;
    } catch (e) {
        // Check if the request was aborted due to a redirect. If so, we handle it gracefully
        if (signal.aborted && signal.reason === REASON_REDIRECT) {
            return;
        }
        _AppVersionState.lastFetchFailureStreak += 1;
        // the impact on the user for a once failing request is pretty low. However, if this happens more than once
        // in a row, we want to track that issue
        if (_AppVersionState.lastFetchFailureStreak > _AppVersionState.failureStreakThreshold) {
            // If fetching the new version fails, we don't show an error to the user since the user is not directly impacted.
            // We just track the error, so we can monitor it, and fix it if it's not a transient error (e.g. network issue).
            LOG.caught(e, "Could not fetch available version");
        }

        return undefined;
    }
};

const refreshVersionInfo = async () => {
    const userStore = useUserStore();
    if (navigator.onLine && userStore.isLoggedIn) {
        const newVersionId = await _fetchNewVersion();
        SkeletonStore.newVersionAvailable = newVersionId !== undefined;
    }
};

const useWatchAppVersion = () => {
    const router = useRouter();
    router.afterEach(refreshVersionInfo);

    addEventListener("focus", refreshVersionInfo);
};

// only for testing
export { _AppVersionState, _fetchNewVersion, _isNewerVersion };
export const AppVersion = {
    useWatchAppVersion,
};
