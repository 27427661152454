<script lang="ts" setup>
import { provide } from "vue";
import WuxSpinner from "../WuxSpinner/WuxSpinner.vue";
import { accordionInjectionKey, useAccordionState, WuxAccordionProps } from "./WuxAccordion.core";

const props = defineProps<WuxAccordionProps>();

const { registerAccordionPanel, openAll, closeAll } = useAccordionState(props);

provide(accordionInjectionKey, registerAccordionPanel);

defineExpose({ openAll, closeAll });
</script>

<template>
    <div class="wux-accordion" :class="{ 'wux-accordion--dense': props.isDense }">
        <slot />
        <div v-if="props.isLoading" class="wux-accordion__loading"><WuxSpinner /></div>
    </div>
</template>

<style lang="scss">
.wux-accordion {
    display: flex;
    flex-direction: column;
    flex: 1;

    // loading indicator
    &__loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: 1rem 0;
        .wux-spinner {
            width: 1.5rem;
            height: 1.5rem;
            align-self: center;
        }
    }
}
</style>
