/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { z } from "zod";

export enum Scope {
    SCOPE_UNKNOWN = "SCOPE_UNKNOWN",
    SCOPE_HQ = "SCOPE_HQ",
    SCOPE_WH = "SCOPE_WH",
    SCOPE_HQWH = "SCOPE_HQWH",
    SCOPE_INT = "SCOPE_INT",
    SCOPE_ALL = "SCOPE_ALL",
}

export const ScopeSchema = z.nativeEnum(Scope).catch(Scope.SCOPE_UNKNOWN);
