import { LOG } from "../skeleton/errors";
import { CSV } from "./csv";
import { DateUtil } from "./date";
import { FileSystemUtil } from "./file-system";

const pathJoin = (firstPart: string, ...pathFragments: Array<string | undefined>) => {
    const joined = pathFragments.filter(Boolean).join("/").replaceAll(/\/+/g, "/");
    return `${firstPart.replace(/\/$/, "")}/${joined.replace(/^\//, "")}`;
};

/**
 *
 * Convert a bigint to a number with a check if the bigint is outside of the safe integer boundaries.
 * It will log an error if the conversion is unsafe, so that these cases can be tracked.
 * If force is `false` (default) then it will return `NaN` in case of an unsafe conversion. Otherwise it will still do perform the conversion.
 *
 * @param bi bigint to convert to a number
 * @param force will perform conversion even if unsafe to do so (default: false)
 */
const unsafeBigIntToNumber = (bi: bigint, force = false): number => {
    if (bi > Number.MAX_SAFE_INTEGER || Number.MIN_SAFE_INTEGER > bi) {
        LOG.errorMsg(
            `BigInt to Number Conversion Error: BigInt ${bi} is outside of safe integer boundaries and will be inaccurate.`,
        );
        if (!force) {
            return NaN;
        }
    }
    return Number(bi);
};

export type CsvRow = string[];
/**
 * Works perfectly for Excel, but note that this may not be supported in other spreadsheet or data programs.
 * It may only be a small issue, simply showing an extra first line with sep= or sep=; in it,
 * but some automated programs or scripts may not expect the extra first line,
 * resulting in the column headers or the sep=; line itself being interpreted as actual data.
 */
const toCSV = (headers: CsvRow, rows: CsvRow[], delimiter = ";"): string =>
    CSV.encode(rows, { headers, delimiter, newline: "\n" });

const fromCSV = (csvText: string, delimiter = ";"): CsvRow[] => CSV.decode(csvText, { delimiter, newline: "\n" });

const downloadCSV = async (filename: string, csv: string) => {
    // TODO(?): mention why this discouraged output is helpful for this software’s users.
    // Prepend CSV with a Byte Order Mark
    // https://stackoverflow.com/questions/17879198/adding-utf-8-bom-to-string-blob
    // it is also needed in when exporting a CSV in Windows.
    // https://stackoverflow.com/questions/6588068/which-encoding-opens-csv-files-correctly-with-excel-on-both-mac-and-windows
    const csvWithUtf8Bom = `\ufeff${csv}`;

    return downloadFile(filename, csvWithUtf8Bom, "text/csv;charset=utf-8", ".csv", true);
};

const downloadFile = async (
    filename: string,
    data: BufferSource | Blob | string,
    contentType: string,
    fileTypeSuffix: string,
    displayFilePicker = false,
) => {
    const handle = displayFilePicker && (await FileSystemUtil.requestFileHandle(filename, contentType, fileTypeSuffix));
    if (handle === "canceled") return; // Don't bother downloading at all if user canceled (and don't fallback either)
    if (handle) {
        // Write the blob to the file.
        const writable = await handle.createWritable();
        await writable.write(data);
        await writable.close();
        return;
    }
    // Fallback if the File System Access API is not supported…
    // Create the blob URL.
    const blob = new Blob([data], { type: contentType });
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    // click the link in order to start download
    a.click();
    // free app resources in the browser
    // https://developer.mozilla.org/en-US/docs/Web/API/URL/revokeObjectURL
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
};

const openFile = async (blob: Blob) => {
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
    // free app resources in the browser
    // https://developer.mozilla.org/en-US/docs/Web/API/URL/revokeObjectURL
    URL.revokeObjectURL(url);
};

export const Utils = {
    pathJoin,
    toCSV,
    fromCSV,
    downloadCSV,
    downloadFile,
    openFile,
    unsafeBigIntToNumber,
    date: DateUtil,
};
