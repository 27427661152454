import { Module } from "@ui/clients/generated/wam/modules";
import { AuthPolicy } from "../auth";
import { isSettingTruthBoolean, LoadingStatus } from "../configs/wawiConfigs";
import { WaWiCountry } from "../country";
import { WaWiLocation } from "../location";
import { getModuleIsActiveFor } from "../module";
import { WaWiPreview } from "../preview";
import { ResolvedRoute } from "./routing";

export enum UnauthorizedReason {
    INVALID_LOCATION = "INVALID_LOCATION",
    UNAUTHORIZED_MODULE = "UNAUTHORIZED_MODULE",
    INACTIVE_MODULE = "INACTIVE_MODULE",
    DISABLED_SETTING = "DISABLED_SETTING",
    INACTIVE_FEATURE = "INACTIVE_FEATURE",
    RESTRICTED_COUNTRY = "RESTRICTED_COUNTRY",
    MISSING_POLICY = "MISSING_POLICY",
}

export const checkRouteAuth = (
    route: ResolvedRoute | undefined,
    currentModule: Module | undefined,
    wawiConfigsStore: {
        configsStatus: LoadingStatus;
        GetSettingValue: (section: string, name: string) => string | undefined;
    },
    featureTogglesStore: {
        featureTogglesStatus: LoadingStatus;
        isFeatureEnabled: (name: string) => boolean;
    },
    userStore: {
        locationsByModule: Record<string, string[]>;
        isAllowedModule: (moduleId: string) => boolean;
        hasPolicy: (policy: AuthPolicy | AuthPolicy[]) => boolean;
    },
): UnauthorizedReason | null => {
    // we want to access all pages in preview mode
    if (WaWiPreview.IS_PREVIEW_MODE) return null;

    const location = WaWiLocation.get();
    if (location && !Object.values(userStore.locationsByModule).flat().includes(location)) {
        return UnauthorizedReason.INVALID_LOCATION;
    }

    if (currentModule && !userStore.isAllowedModule(currentModule.id)) {
        return UnauthorizedReason.UNAUTHORIZED_MODULE;
    }

    if (currentModule && (location || WaWiCountry.isInternational())) {
        if (!getModuleIsActiveFor(currentModule, WaWiCountry.get(), location)) {
            return UnauthorizedReason.INACTIVE_MODULE;
        }
    }

    if (!route) {
        return null;
    }

    const activationSetting = route.activationSetting;
    if (
        activationSetting &&
        wawiConfigsStore.configsStatus === LoadingStatus.READY &&
        // obtaining a parsed boolean setting is currently not possible
        // after story WAM-MZ40KZ is finished, this code should be adapted to obtain the setting as boolean directly
        !isSettingTruthBoolean(
            wawiConfigsStore.GetSettingValue(activationSetting.section, activationSetting.name),
            activationSetting.trueValues,
        )
    ) {
        return UnauthorizedReason.DISABLED_SETTING;
    }

    const requiredToggles = route.requiredToggles;
    if (
        requiredToggles &&
        featureTogglesStore.featureTogglesStatus === LoadingStatus.READY &&
        requiredToggles.some((t) => featureTogglesStore.isFeatureEnabled(t.name) === !!t.hideIfActive)
    ) {
        return UnauthorizedReason.INACTIVE_FEATURE;
    }

    const allowedCountries = route.allowedCountries;
    if (allowedCountries && !allowedCountries.some((c) => c === WaWiCountry.get())) {
        return UnauthorizedReason.RESTRICTED_COUNTRY;
    }

    const requiredPolicy = route.requiredPolicy;
    // TODO(AC): If the requiredPolicy is not set, we allow routing to it. Remove once all apps define policies
    if (requiredPolicy && !userStore.hasPolicy(requiredPolicy)) {
        return UnauthorizedReason.MISSING_POLICY;
    }
    return null;
};
