import { type Component } from "vue";
import { MessageOrId } from "../../i18n";

export enum DialogType {
    SUCCESS = "SUCCESS",
    CONFIRM = "CONFIRM",
    INFO = "INFO",
    ERROR = "ERROR",
}

export type DialogAction = {
    labelMsg: MessageOrId;
    isLoading?: boolean;
    isDisabled?: boolean;
    onClick?: () => void;
};

export type WuxDialogProps = {
    /**
     * Allows to trigger the cancel action and close the dialog when user clicks outside the dialog.
     */
    isDismissible?: boolean;
    /**
     * Will be shown in the header of the dialog.
     */
    headerMsg?: MessageOrId;
    /**
     * Will be shown in the content of the dialog.
     */
    contentMsg?: MessageOrId;
    /**
     * Can be used pass in any kind of content.
     */
    content?: { component: Component };
    /**
     * Will be shown as a foot note in the dialog content.
     */
    footNoteMsg?: MessageOrId;
    /**
     * Configures the primary action.
     */
    primary: DialogAction;
    /**
     * Allows to configure multiple secondary actions.
     */
    secondary?: DialogAction[];
    /**
     * Determines the set of action buttons and the look of the dialog.
     */
    type?: DialogType;
    /**
     * Will hide the dialog. Can be used for the transition states.
     */
    isHidden?: boolean;
};
