import { DateUtil as ClientDateUtil, type GoogleDate } from "@ui/clients";

/**
 * Validate if the given date parameters represent an existing date.
 */
export const _isValid = (year: number, monthIndex: number, day: number) => {
    const actualDate = new Date(year, monthIndex, day);
    const actualDay = actualDate.getDate();
    const actualMonth = actualDate.getMonth();
    const actualYear = actualDate.getFullYear();
    return actualDay === day && actualMonth === monthIndex && actualYear === year;
};

/**
 * Will try to parse a ISO-8601 calendar date string.
 * It behaves differently from `new Date(...)` because it creates the `Date` in the local timezone and not as UTC.
 *
 * Does not yet support times and will drop them.
 */
const tryParse = (dateString: string) => {
    const dateOnlyString = dateString.split(" ").at(0) ?? ""; // remove potential time stamp
    const [year, month, day] = dateOnlyString.split("-").map((d) => +d);
    const monthIndex = month - 1;
    const isValidDate = _isValid(year, monthIndex, day);
    return isValidDate ? new Date(year, monthIndex, day) : new Date(NaN);
};

/**
 * Returns Number.MIN_SAFE_INTEGER in case of invalid date.
 */
const _getMsDateWithoutTime = (date: Date | GoogleDate): number =>
    date instanceof Date
        ? isNaN(+date)
            ? Number.MIN_SAFE_INTEGER
            : Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
        : _getMsDateWithoutTime(ClientDateUtil.convertGoogleDate(date));

/**
 * Will only compare the year/month/day of the given dates.
 */
const compare = (a: Date | GoogleDate, b: Date | GoogleDate): -1 | 0 | 1 =>
    Math.sign(_getMsDateWithoutTime(a) - _getMsDateWithoutTime(b)) as -1 | 0 | 1;

/**
 * Will only compare the year/month/day of the given dates.
 */
const equals = (a?: Date | GoogleDate, b?: Date | GoogleDate): boolean => {
    if (!a || !b) return a === b;
    return compare(a, b) === 0;
};

export const DateUtil = { tryParse, equals, compare };
