/**
 *
 * This code is used inside the application to handle the scenario where a user
 * is redirected to a new URL, which might cause ongoing fetch requests to fail.
 * To prevent the "failed to fetch" errors, we abort all ongoing requests before redirecting.
 *
 * Consumers of this abort controller can detect if a fetch was aborted due to a redirect
 * by checking the reason provided when the request is aborted.
 *
 **/
const abortController = new AbortController();
export const signal = abortController.signal;
export const REASON_REDIRECT = "REDIRECT";

export const abortRequests = () => {
    abortController.abort(REASON_REDIRECT);
};
