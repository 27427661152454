<script lang="ts" setup>
import { ref } from "vue";
import { __, MessageOrId, useMsgFormatter } from "../../i18n";
import { ComponentUtils } from "../../utils";
import { Shortcut } from "../../utils/shortcuts";
import { Tab as TabType } from "../WuxTabBar/WuxTabBar.core";
import WuxTabBar from "../WuxTabBar/WuxTabBar.vue";
import WuxShortcutKeys from "./WuxShortcutKeys.vue";

export type ShortcutCategory = { categoryMsg: MessageOrId; shortcuts: ShortcutEntry[] };
export type ShortcutEntry = {
    shortcut: Shortcut;
    macOnly?: boolean;
    windowsOnly?: boolean;
    descriptionMsg: MessageOrId;
};

const props = defineProps<{
    shortcuts: ShortcutCategory[];
}>();

const { m } = useMsgFormatter();

const tabs = [
    {
        id: "windows",
        labelMsg: __("ui.components.shortcuts.windows"),
    },
    {
        id: "mac",
        labelMsg: __("ui.components.shortcuts.mac"),
    },
] as TabType[];
const initialTab: (typeof tabs)[number]["id"] = ComponentUtils.isMacOS() ? "mac" : "windows";
const currentTab = ref(initialTab);
</script>

<template>
    <div class="wux-shortcuts">
        <WuxTabBar :tabs="tabs" v-model="currentTab">
            <template #windows>
                <div class="wux-shortcuts__categories">
                    <div v-for="({ categoryMsg, shortcuts }, i) in props.shortcuts" :key="i">
                        <h3>{{ m(categoryMsg) }}</h3>
                        <div class="wux-shortcuts__entries">
                            <template
                                v-for="{ shortcut, descriptionMsg } in shortcuts.filter((s) => !s.macOnly)"
                                :key="shortcut.toString()"
                            >
                                <WuxShortcutKeys :shortcut="shortcut" />
                                <div>{{ m(descriptionMsg) }}</div>
                                <hr />
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <template #mac>
                <div class="wux-shortcuts__categories">
                    <div
                        class="wux-shortcuts__category"
                        v-for="({ categoryMsg, shortcuts }, i) in props.shortcuts"
                        :key="i"
                    >
                        <h3>{{ m(categoryMsg) }}</h3>
                        <div class="wux-shortcuts__entries">
                            <template
                                v-for="{ shortcut, descriptionMsg } in shortcuts.filter((s) => !s.windowsOnly)"
                                :key="shortcut.toString()"
                            >
                                <WuxShortcutKeys :shortcut="shortcut" isMac />
                                <div>{{ m(descriptionMsg) }}</div>
                                <hr />
                            </template>
                        </div>
                    </div>
                </div>
            </template>
        </WuxTabBar>
    </div>
</template>

<style lang="scss">
.wux-shortcuts {
    padding: 0 2em 2em;

    &__entries {
        display: grid;
        grid-template-columns: min-content auto;
        align-items: center;
        width: 25em;
        column-gap: 1em;

        hr {
            grid-column: 1 / -1;
            width: 100%;
            margin: 0.5em 0;
        }
    }

    &__categories {
        display: flex;
        flex-wrap: wrap;
        gap: 2em;
    }
}
</style>
