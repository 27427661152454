<script setup lang="ts">
import { __, DialogAction, WuxDialog, WuxShortcuts } from "@ui/components";
import { ShortcutInfo } from "./shortcuts";

const primary: DialogAction = { labelMsg: __("ui.libs.shortcut-dialog.ok"), onClick: ShortcutInfo.close };
</script>

<template>
    <WuxDialog
        :primary="primary"
        isDismissible
        :headerMsg="__('ui.libs.shortcut-dialog.title')"
        @dismiss="ShortcutInfo.close"
    >
        <WuxShortcuts :shortcuts="ShortcutInfo.list" />
    </WuxDialog>
</template>

<style scoped lang="scss">
.wux-shortcuts {
    padding-bottom: 2em;
}
</style>
