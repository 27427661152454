import { computed, ComputedRef } from "vue";
import { Module as WamModule } from "@ui/clients/generated/wam/modules";
import { OptMessageFormatter } from "../../components/src/i18n";
import { useUserStore } from "../auth/user";
import { WaWiCountry } from "../country/country";
import { WaWiLocation } from "../location/location";
import { LOG } from "../skeleton/errors";
import { Utils } from "../utils/utils";
import { CurrentModule } from "./current";
import { Module, modules } from "./registry";
import { ModulesStore, useModulesStore } from "./store";

const navigateToModule = ({
    modulePath,
    newTab,
    subPath = "/",
}: {
    modulePath: string;
    newTab?: boolean;
    subPath?: string;
}) => window.open(buildModuleUrl({ modulePath, subPath }), newTab ? "_blank" : "_self");

const getModuleUrl = ({ moduleName, subPath }: { moduleName: string; subPath?: string }): string => {
    const modulePath = modules.find(({ name }) => name === moduleName)?.url;
    if (!modulePath) return "";
    return buildModuleUrl({ modulePath, subPath });
};

const buildModuleUrl = ({ modulePath, subPath }: { modulePath: string; subPath?: string }): string =>
    Utils.pathJoin(modulePath, WaWiLocation.get()?.toLowerCase(), subPath);

const set = ({ moduleName, newTab, subPath }: { moduleName: string; newTab?: boolean; subPath?: string }) => {
    const modulePath = modules.find(({ name }) => name === moduleName)?.url;
    if (modulePath) {
        navigateToModule({ modulePath, newTab, subPath });
    } else {
        LOG.errorMsg(`Given module "${moduleName}" does not exist.`);
    }
};

const setLaunchpad = (opts?: { newTab?: boolean; subPath?: string }) =>
    navigateToModule({ modulePath: "/module/launchpad", ...opts });

export const useAvailableModules = (): ComputedRef<Module[]> => {
    const userStore = useUserStore();
    const modulesStore = useModulesStore();
    const isInternational = WaWiCountry.isInternational();
    const country = WaWiCountry.get();
    const location = WaWiLocation.get();
    return computed((): Module[] => {
        if (!isInternational && !location) {
            return [];
        }
        return modules.filter(
            (m) =>
                userStore.isAllowedModule(m.name) &&
                getModuleIsActiveFor(modulesStore.modules[m.name], country, location),
        );
    });
};

export const useCurrentModule = () =>
    computed(() => {
        const currentModule = CurrentModule.get();
        return currentModule ? ModulesStore.modules[currentModule.name] : undefined;
    });

export const getModuleIsActiveFor = (
    module: WamModule | undefined,
    country: string,
    location: string | undefined,
): boolean =>
    module?.activeLocations.some((activeLocation) => {
        if (activeLocation.country === country) {
            // INT country has no locations, INT module activations are represented in * location
            if (country === "INT" && activeLocation.locations.some((l) => l === "*")) {
                return true;
            }

            if (activeLocation.locations.some((l) => l.toUpperCase() === location)) {
                return true;
            }
        }

        return false;
    }) || false;

export const useActiveLocations = (country: string): ComputedRef<Set<string>> =>
    computed(() => {
        const locations = new Set<string>();

        for (const id in ModulesStore.modules) {
            const module = ModulesStore.modules[id];
            module.activeLocations.forEach((activeLocation) => {
                if (activeLocation.country === country) {
                    activeLocation.locations.forEach((l) => locations.add(l));
                }
            });
        }
        return locations;
    });

export const getModuleFullName = (m: OptMessageFormatter, module?: Module) => {
    if (!module) {
        return undefined;
    }

    return module.name + " - " + m(module.descriptionMsg);
};

export const WaWiModule = { set, setLaunchpad, getModuleUrl, buildModuleUrl };
