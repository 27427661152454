/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { type Endpoint, HTTPMethod } from "../../../src/runtime";
import { type ListFeatureTogglesRequest } from "../configs/ListFeatureTogglesRequest";
import { type ListFeatureTogglesResponse, ListFeatureTogglesResponseSchema } from "../configs/ListFeatureTogglesResponse";

export const ListConfigsFeatureToggles: Endpoint<ListFeatureTogglesResponse, ListFeatureTogglesRequest, void, void> = {
    method: HTTPMethod.POST,
    path: "/wam/configs/feature-toggles",
    parser: ListFeatureTogglesResponseSchema.parse,
};
