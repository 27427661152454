import { WaWiCountry } from "../../country/country";
import { getLanguageOptions, Language } from "./options";

const FALLBACK_LANGUAGE = "en-GB";
const LANGUAGE_KEY = "wawi-language";

const getSupportedLanguages = (): Language[] => getLanguageOptions().map(({ value }) => value);

const getConfiguredLanguage = (languages: Language[]): Language | undefined => {
    const lang = localStorage.getItem(LANGUAGE_KEY);
    return lang ? languages.find((l) => l === lang) : undefined;
};

const ShortLanguageCodeMappings: Record<string, Language | undefined> = {
    pt: "pt-PT",
    de: "de-DE",
    en: "en-GB",
};
// `string[]` because derived from `navigator.languages`
const getUserLanguages = (): string[] =>
    // example: navigator.languages = ["fr", "en-US", "en", "de-DE"]
    navigator.languages
        // changed to: ["fr", "en-US", "en-GB", "de-DE"]
        .map((l) => ShortLanguageCodeMappings[l] || l)
        // changed to: ["en-US", "en-GB", "de-DE"]
        .filter((l) => l.length > 2);

const getCurrentLanguage = (): Language => {
    // 1. prefer manually configured lang
    const supportedLanguages = getSupportedLanguages();
    const configuredLang = getConfiguredLanguage(supportedLanguages);
    if (configuredLang) return configuredLang;

    // 2. select country language if supported by the user
    const countryLanguage = WaWiLanguage.defaultCountyLocales[WaWiCountry.get()];
    const userLanguages = getUserLanguages();
    if (countryLanguage && userLanguages.includes(countryLanguage)) return countryLanguage;

    // 3. we pick a language the user understands and we support
    // we respect the order of languages because this is explicitly configurable by the user
    const supportedUserLang = userLanguages.map((ul) => supportedLanguages.find((sl) => sl === ul)).find(Boolean);
    if (supportedUserLang) return supportedUserLang;

    // 4. we return the default country language if it exists. Otherwise, the fallback language.
    return countryLanguage || FALLBACK_LANGUAGE;
};

const setCurrentLanguage = (lang: string) => {
    localStorage.setItem(LANGUAGE_KEY, lang);
    location.reload();
};

const getCurrentOption = () => {
    const current = WaWiLanguage.get();
    // it is guaranteed that the result is a value within the set of all language options
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return getLanguageOptions().find(({ value }) => value === current)!;
};

export const WaWiLanguage = {
    set: setCurrentLanguage,
    get: getCurrentLanguage,
    getCurrentOption,
    getOptions: getLanguageOptions,
    defaultCountyLocales: {} as Record<string, Language | undefined>,
};
