import { computed, reactive } from "vue";
import { __, ComponentUtils, ElementSelector, MessageOrId, WuxGuidedTourStepProps } from "@ui/components";
import { z } from "zod";
import { AuthPolicy } from "../auth/policies";

export type GuidedTourStep = ElementSelector & {
    titleMsg: MessageOrId;
    contentMsg: MessageOrId;
    requiredPolicies?: AuthPolicy[];
    setUp?: () => Promise<void>;
    cleanUp?: () => Promise<void>;
    isVisible?: () => boolean | Promise<boolean>;
};

type Props = {
    steps: GuidedTourStep[];
    index: number;
    props?: WuxGuidedTourStepProps;
    tourName: string; // the current router name (used to check weather the tour is taken or not)
    showTourIntro?: boolean;
    isLoading?: boolean;
    isActive?: boolean;
};

const GUIDED_TOUR_KEY = "wawi-taken-guided-tours";

const guidedTourStore = reactive<Props>({
    tourName: "",
    steps: [],
    index: 0,
});

export const useGuidedTourStore = (): Readonly<Props> => guidedTourStore;

export const useGuidedTourStatus = () => ({
    isGuidedTourLoading: computed(() => guidedTourStore.isLoading),
    isGuidedTourActive: computed(() => guidedTourStore.isActive),
});

// To execute async setUp or cleanUp functions and to manage the loading state.
const executeBetweenSteps = async (func?: () => Promise<void>) => {
    const props = guidedTourStore.props;
    if (!func) return;

    if (props) {
        props.isStepLoading = true;
    }
    guidedTourStore.isLoading = true;
    await func();
    if (props) {
        props.isStepLoading = false;
    }
    guidedTourStore.isLoading = false;
};

// Initializing the step
const __setGuidedTourProps = async () => {
    if (guidedTourStore.steps && guidedTourStore.index < guidedTourStore.steps.length) {
        const currentStep = guidedTourStore.steps[guidedTourStore.index];

        // execute setUp function
        if (currentStep?.setUp) {
            await executeBetweenSteps(currentStep.setUp);
        }

        guidedTourStore.props = {
            currentStep: guidedTourStore.index + 1,
            totalSteps: guidedTourStore.steps.length,
            titleMsg: currentStep.titleMsg,
            contentMsg: currentStep.contentMsg,
            tourId: currentStep.tourId,
            selector: currentStep.selector,
        } as WuxGuidedTourStepProps;
    }
};

const __loadTakenTours = (): Set<string> => {
    const tour = localStorage.getItem(GUIDED_TOUR_KEY);
    const takenTours = ComponentUtils.stringToJSONSchema().pipe(z.array(z.string())).catch([]).parse(tour);
    return new Set(takenTours);
};

const __isNotTaken = () => {
    const takenTours = __loadTakenTours();
    return !takenTours.has(guidedTourStore.tourName);
};

const __addToListOfTakenTours = () => {
    const takenTours = __loadTakenTours();
    takenTours.add(guidedTourStore.tourName);
    localStorage.setItem(GUIDED_TOUR_KEY, JSON.stringify([...takenTours]));
};

// Move to the next step
const next = async () => {
    if (guidedTourStore.steps && guidedTourStore.index < guidedTourStore.steps.length) {
        // cleanUp function is executed before navigating to the NEXT step
        const currentStep = guidedTourStore.steps[guidedTourStore.index];
        if (currentStep?.cleanUp) {
            await executeBetweenSteps(currentStep.cleanUp);
        }

        guidedTourStore.index += 1;
        if (guidedTourStore.index >= guidedTourStore.steps.length) {
            complete();
        } else {
            __setGuidedTourProps();
        }
    }
};

const previous = async () => {
    if (guidedTourStore.steps && guidedTourStore.index < guidedTourStore.steps.length) {
        // cleanUp function is executed before navigating to the PREVIOUS step
        // except the last step as it is also used to restore the interface to its original state if needed.
        const currentStep = guidedTourStore.steps[guidedTourStore.index];
        if (currentStep?.cleanUp && guidedTourStore.index !== guidedTourStore.steps.length - 1) {
            await executeBetweenSteps(currentStep.cleanUp);
        }

        guidedTourStore.index -= 1;
        if (guidedTourStore.index < 0) {
            return;
        }
        __setGuidedTourProps();
    }
};

const complete = async () => {
    // cleanUp function is executed before completing the tour
    if (guidedTourStore.steps && guidedTourStore.index < guidedTourStore.steps.length) {
        await executeBetweenSteps(guidedTourStore.steps[guidedTourStore.index]?.cleanUp);
        // execute the cleanUp function of the last step if the guided tour is cancelled in the middle.
        if (guidedTourStore.index < guidedTourStore.steps.length - 1) {
            await executeBetweenSteps(guidedTourStore.steps.at(-1)?.cleanUp);
        }
    }
    guidedTourStore.showTourIntro = false;
    guidedTourStore.props = undefined;
    guidedTourStore.isActive = false;
    __addToListOfTakenTours();
};

// Initiate the tour steps in the store
const prepare = (title: string, steps: GuidedTourStep[]) => {
    guidedTourStore.steps = steps;

    guidedTourStore.index = 0;
    guidedTourStore.props = undefined;
    guidedTourStore.tourName = title;
    guidedTourStore.showTourIntro = false;

    // Automatically start the tour if it is not taken yet
    if (guidedTourStore.steps.length) {
        guidedTourStore.showTourIntro = __isNotTaken();
    }
};

const start = () => {
    guidedTourStore.showTourIntro = false;
    if (guidedTourStore.steps.length < 1) {
        return;
    }
    guidedTourStore.index = 0;
    guidedTourStore.isActive = true;
    __setGuidedTourProps();
};

const skipTour = async () => {
    guidedTourStore.showTourIntro = false;
    guidedTourStore.props = undefined;
    guidedTourStore.isActive = false;
    __addToListOfTakenTours();
};

export const GuidedTour = { start };
export const InternalGuidedTour = { complete, next, previous, prepare, skipTour };
