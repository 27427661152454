<script setup lang="ts">
import { computed, watch } from "vue";
import { Section } from "@ui/clients/generated/wam/settings";
import { __, WuxSpinner } from "@ui/components";
import { useUserStore } from "../auth/user";
import { WaWiCountry } from "../country/country";
import { WaWiLocation } from "../location/location";
import { Dialog } from "../skeleton/Dialog";
import {
    loadFeatureToggles,
    LoadingStatus,
    loadWawiConfigs,
    useFeatureTogglesStore,
    useWawiConfigsStore,
} from "./wawiConfigs";

type Props = {
    settingsSections: Section[]; // Only settings belonging to sections specified here are loaded (usually, current module + SHARED).
    featureToggles: string[]; // Only feature toggles with name belonging to this list are loaded.
};

const props = defineProps<Props>();

const userStore = useUserStore();

// We only render the component in the main slot if the current page can not load configs, is finished loading the configs or has an empty settings section prop and empty featureToggles prop.
// The following computed values reflect these states.
const canLoadConfigs = computed<boolean>(
    () => userStore.isLoggedIn && (WaWiCountry.isInternational() || WaWiLocation.get() ? true : false),
);
const finishedLoadingConfigs = computed<boolean>(
    () =>
        wawiConfigsStore.configsStatus === LoadingStatus.READY ||
        wawiConfigsStore.configsStatus === LoadingStatus.ERROR,
);
const finishedLoadingFeatureToggles = computed<boolean>(
    () =>
        featureTogglesStore.featureTogglesStatus === LoadingStatus.READY ||
        featureTogglesStore.featureTogglesStatus === LoadingStatus.ERROR,
);

const emptySettingsSections = computed<boolean>(() => props.settingsSections.length === 0);
const emptyFeatureToggles = computed<boolean>(() => props.featureToggles.length === 0);

if (canLoadConfigs.value) {
    loadWawiConfigs(props.settingsSections);
    loadFeatureToggles(props.featureToggles);
}

const wawiConfigsStore = useWawiConfigsStore();
const featureTogglesStore = useFeatureTogglesStore();

watch(
    () => [wawiConfigsStore.configsStatus, featureTogglesStore.featureTogglesStatus],
    () => {
        if (
            wawiConfigsStore.configsStatus === LoadingStatus.ERROR ||
            featureTogglesStore.featureTogglesStatus === LoadingStatus.ERROR
        ) {
            Dialog.showError({
                headerMsg: __("ui.libs.settings.fetch-failed-headline"),
                contentMsg: __("ui.libs.settings.fetch-failed-message"),
                showReloadButton: true,
            });
        }
    },
    { immediate: true },
);
</script>

<template>
    <WuxSpinner
        v-if="
            wawiConfigsStore.configsStatus === LoadingStatus.LOADING ||
            featureTogglesStore.featureTogglesStatus === LoadingStatus.LOADING
        "
        type="page"
        :labelMsg="__('ui.libs.settings.loading-message')"
    />
    <slot
        v-if="
            !canLoadConfigs ||
            ((finishedLoadingConfigs || emptySettingsSections) &&
                (finishedLoadingFeatureToggles || emptyFeatureToggles))
        "
    />
</template>
