import { reactive } from "vue";
import { WaWiCountry } from "../country/country";
import { WaWiLocation } from "../location/location";
import { WaWiPreview } from "../preview/preview";
import { LOG } from "../skeleton/errors";
import { AuthPolicy } from "./policies";

// period of time for which refresh token is valid, (hours * minutes * seconds * milliseconds)
export const WAWI_REFRESH_TOKEN_LIFETIME = 8 * 60 * 60 * 1000;

export const accessTokenKey = "wawi-access-token";
export const tokenExpirationDateKey = "tokenExpirationDate";
export const lastRefreshTimestampKey = "tokenRefreshTimestamp";
export const locationsByModuleKey = "wawi-locations-by-module";
export const userCountriesKey = "user-countries";

type PolicyList = {
    d: string[] | null;
    l: string[] | null;
    p: string[] | null;
}[];

export type TokenClaims = {
    sudo: boolean;
    modules: string[];
    policies: PolicyList;
    aadUserId: string;
    nexusModUser: string; // == workforceId for regular WaWi users. User in postgres DB tables.
    legacyModUser: string; // encoded version of the nexusModUser. Used in legacy Oracle tables.
    sub: string;
    name: string;
    email: string;
};

// Based on https://github.com/auth0/jwt-decode/
// I removed the unnecessary padding by "=" in the end of the base64 encoded string.
// This function can throw an error at several places, please call only within a try/catch block.
export const parseJwt = (token: string): TokenClaims | undefined => {
    try {
        const base64Url = token.split(".")[1];
        // JWTs are base64URL encoded, which is not the same as base64 encoding -> replace - and _ characters.
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        // In order to reconstruct the original characters used in the encoding of the JWT (i.e. greek letters)
        // we need to URI encode the contained characters, so they can be recovered by the decodeURIComponent below.
        const uriEncoded = atob(base64).replace(/(.)/g, (_, found) => {
            const code = found.charCodeAt(0).toString(16).toUpperCase();
            if (code.length < 2) {
                return "%0" + code;
            }
            return "%" + code;
        });
        const jsonPayload = decodeURIComponent(uriEncoded);
        return JSON.parse(jsonPayload);
    } catch (e) {
        LOG.caught(e, accessTokenKey + " is not a real token");
    }
};

export const getLastRefreshTimestamp = (): number | null => {
    const lastRefreshTimestamp = localStorage.getItem(lastRefreshTimestampKey);
    return lastRefreshTimestamp ? Number(lastRefreshTimestamp) : null;
};

type Location = string;
type PolicyId = string;
type Domain = string;

export type PolicyMap = Record<string, Set<string>>;
export type InternalPolicyMap = Record<Location, Record<PolicyId, Set<Domain>>>;

export const getPolicies = (claims?: TokenClaims): InternalPolicyMap => {
    const policyList: PolicyList = claims?.policies || [];

    const policyMap: InternalPolicyMap = {};
    policyList.forEach((entry) =>
        entry.l?.forEach((loc) => {
            if (!(loc in policyMap)) {
                policyMap[loc] = {};
            }

            entry.p?.forEach((policy) => {
                if (!(policy in policyMap[loc])) {
                    policyMap[loc][policy] = new Set();
                }
                entry.d?.forEach((domain) => policyMap[loc][policy].add(domain));
            });
        }),
    );
    return policyMap;
};

const getSub = (claims?: TokenClaims): string => claims?.sub || "";

const getLegacyModUser = (claims?: TokenClaims): string => claims?.legacyModUser || "";

const getAADUserId = (claims?: TokenClaims): string => claims?.aadUserId || "";

const getName = (claims?: TokenClaims): string => claims?.name || "";

const getEmail = (claims?: TokenClaims): string => claims?.email || "";

type UserState = {
    policies: null | InternalPolicyMap;
};

export type PublicUserState = {
    isLoggedIn: boolean;
    // this function has to be part of the reactive store, so that any policy updates allow to re-iterate on its results
    hasPolicy: (policy: AuthPolicy | AuthPolicy[]) => boolean;
    getLocationsForPolicy: (policy: AuthPolicy) => string[];
    getDomainsForPolicyAndCountry: (policy: AuthPolicy, country: string) => string[];
    aadUserId: string;
    nexusModUser: string;
    legacyModUser: string;
    displayName: string;
    email: string;
    userCountries: string[];
    locationsByModule: Record<string, string[]>;
    isAllowedModule: (moduleId: string) => boolean;
};

const initialTokenInfo = ((): {
    policies: UserState["policies"];
    aadUserId: string;
    nexusModUser: string;
    legacyModUser: string;
    displayName: string;
    email: string;
} => {
    const token = sessionStorage.getItem(accessTokenKey);
    const lastRefreshTimestamp = getLastRefreshTimestamp();
    if (!token || (lastRefreshTimestamp && lastRefreshTimestamp + WAWI_REFRESH_TOKEN_LIFETIME < Date.now()))
        return {
            policies: null,
            aadUserId: "",
            nexusModUser: "",
            legacyModUser: "",
            displayName: "",
            email: "",
        };
    const claims = parseJwt(token);
    return {
        policies: getPolicies(claims),
        aadUserId: getAADUserId(claims),
        nexusModUser: getSub(claims),
        legacyModUser: getLegacyModUser(claims),
        displayName: getName(claims),
        email: getEmail(claims),
    };
})();

const UserStore = reactive<UserState>({
    policies: initialTokenInfo.policies,
});

export const createHasPolicy = () => {
    // This is a workaround for the issue that many teams added write policies to the requiredPolicies
    // on pages, where they are actually not required. This way we can still allow some form of access in
    // an emergency support situation without sacrificing security (as unauthorized calls to the backend will still fail)
    // TODO (WAM-6KI6G5): We should remove it when all modules have been migrated to a correct usage of requiredPolicies
    // at some point in 2025
    if (sessionStorage.getItem("wawi-support-no-policy-check")) {
        return () => true;
    }

    if (WaWiCountry.isInternational()) {
        return (policy: AuthPolicy | AuthPolicy[]): boolean => {
            if (Array.isArray(policy)) {
                return policy.every((p) => hasPolicyInAnyLocation(p));
            }
            return hasPolicyInAnyLocation(policy);
        };
    }
    return (policy: AuthPolicy | AuthPolicy[]): boolean => {
        const currentLocation = WaWiLocation.get();
        if (!currentLocation) {
            return false;
        }
        if (Array.isArray(policy)) {
            return policy.every((p) => hasPolicy(p, currentLocation));
        }
        return hasPolicy(policy, currentLocation);
    };
};

const isAllowedModule = (moduleId: string): boolean => {
    const isInternational = WaWiCountry.isInternational();
    const userStore = useUserStore();

    const location = WaWiLocation.get();
    if (isInternational || !location) {
        return "ALL_MODULES" in userStore.locationsByModule || moduleId in userStore.locationsByModule;
    }

    return (
        userStore.locationsByModule["ALL_MODULES"]?.includes(location) ||
        userStore.locationsByModule[moduleId]?.includes(location)
    );
};

export const createGetLocationsForPolicy =
    () =>
    (policy: AuthPolicy): string[] =>
        Object.entries(UserStore.policies || {})
            .filter(([_, scopedPolicies]) => !!scopedPolicies[policy.Id])
            .map(([loc]) => loc)
            .sort();

export const createGetDomainsForPolicyAndCountry =
    () =>
    (policy: AuthPolicy, country: string): string[] => {
        const policies = UserStore.policies || {};
        const polDomains = Array.from(policies[country]?.[policy.Id] || {});
        const wildcardDomains = Array.from(policies[country]?.["*"] || {});
        return polDomains.concat(wildcardDomains);
    };

const hasPolicy = (policy: AuthPolicy, location: string): boolean => !!UserStore.policies?.[location]?.[policy.Id];

const hasPolicyInAnyLocation = (policy: AuthPolicy) => createGetLocationsForPolicy()(policy).length > 0;

export const hasAnyPolicyInLocation = () => {
    if (!UserStore.policies) {
        return false;
    }
    if (WaWiCountry.isInternational()) {
        return Object.keys(UserStore.policies).length > 0;
    }

    const currentLocation = WaWiLocation.get();
    if (!currentLocation) {
        return false;
    }
    return Object.keys(UserStore.policies[currentLocation] ?? {}).length > 0;
};

const PublicUserStore = reactive<PublicUserState>({
    isLoggedIn: !!initialTokenInfo.policies,
    hasPolicy: createHasPolicy(),
    getLocationsForPolicy: createGetLocationsForPolicy(), // returns a sorted list of locations/countries where the policy is present
    getDomainsForPolicyAndCountry: createGetDomainsForPolicyAndCountry(),
    aadUserId: initialTokenInfo.aadUserId,
    nexusModUser: initialTokenInfo.nexusModUser,
    legacyModUser: initialTokenInfo.legacyModUser,
    displayName: initialTokenInfo.displayName,
    email: initialTokenInfo.email,
    userCountries: JSON.parse(sessionStorage.getItem(userCountriesKey) || JSON.stringify([])),
    locationsByModule: JSON.parse(sessionStorage.getItem(locationsByModuleKey) || JSON.stringify({})),
    isAllowedModule,
});

export const _convertToInternalPolicyMap = (policyMap: PolicyMap): InternalPolicyMap => {
    const internalPolicyMap: InternalPolicyMap = {};
    for (const [location, scopedPolicies] of Object.entries(policyMap)) {
        if (!(location in internalPolicyMap)) {
            internalPolicyMap[location] = {};
        }

        scopedPolicies.forEach((policy) => {
            if (!(policy in internalPolicyMap[location])) {
                internalPolicyMap[location][policy] = new Set();
            }
        });
    }

    return internalPolicyMap;
};

export const _setPolicies = (policies: PolicyMap | null) => {
    const internalPolicyMap = policies ? _convertToInternalPolicyMap(policies) : null;

    _setPoliciesWithDomain(internalPolicyMap);
};

export const _setPoliciesWithDomain = (policies: InternalPolicyMap | null) => {
    UserStore.policies = policies;
    PublicUserStore.isLoggedIn = !!policies;
    // by updating this property all UI depending on this function will be updated, too
    PublicUserStore.hasPolicy = createHasPolicy();
    PublicUserStore.getLocationsForPolicy = createGetLocationsForPolicy();
    PublicUserStore.getDomainsForPolicyAndCountry = createGetDomainsForPolicyAndCountry();
};

export const _setAADUserId = (aadUserId: string | null) => {
    PublicUserStore.aadUserId = aadUserId || "";
};

export const _setNexusModUser = (nexusModUser: string | null) => {
    PublicUserStore.nexusModUser = nexusModUser || "";
};

export const _setLegacyModUser = (legacyModUser: string | null) => {
    PublicUserStore.legacyModUser = legacyModUser || "";
};

export const _setDisplayName = (name: string | null) => {
    PublicUserStore.displayName = name || "";
};

export const _setEmail = (email: string | null) => {
    PublicUserStore.email = email || "";
};

export const _setUserCountries = (countries: string[]) => {
    PublicUserStore.userCountries = countries;
};

export const _setUserLocationsByModule = (locationsByModule: Record<string, string[]>) => {
    PublicUserStore.locationsByModule = locationsByModule;
};

export const useUserStore = (): Readonly<PublicUserState> => {
    if (WaWiPreview.IS_PREVIEW_MODE) {
        return WaWiPreview.useMockedUserStore();
    }

    return PublicUserStore;
};

export const removeUserToken = () => {
    sessionStorage.removeItem(accessTokenKey);
    sessionStorage.removeItem(tokenExpirationDateKey);
    sessionStorage.removeItem(locationsByModuleKey);
    sessionStorage.removeItem(userCountriesKey);
    localStorage.removeItem(lastRefreshTimestampKey);
    _setPolicies(null);
    _setAADUserId(null);
    _setNexusModUser(null);
    _setLegacyModUser(null);
    _setDisplayName(null);
    _setEmail(null);
    _setUserCountries([]);
    _setUserLocationsByModule({});
};

export const setUserTokenAndExpirationDate = (
    token: string,
    expiresAt: number,
    allowedModules: Record<string, string[]>,
    userCountries: string[],
) => {
    sessionStorage.setItem(accessTokenKey, token);
    sessionStorage.setItem(tokenExpirationDateKey, String(expiresAt));
    sessionStorage.setItem(locationsByModuleKey, JSON.stringify(allowedModules));
    sessionStorage.setItem(userCountriesKey, JSON.stringify(userCountries));
    localStorage.setItem(lastRefreshTimestampKey, Date.now().toString());
    const claims = parseJwt(token);
    _setPoliciesWithDomain(getPolicies(claims));
    _setAADUserId(getAADUserId(claims));
    _setNexusModUser(getSub(claims));
    _setLegacyModUser(getLegacyModUser(claims));
    _setDisplayName(getName(claims));
    _setEmail(getEmail(claims));
    _setUserCountries(userCountries);
    _setUserLocationsByModule(allowedModules);
};

export const createAuthHeaders = () => {
    const headers: HeadersInit = {};
    const originModule = window.location.pathname.startsWith("/module/")
        ? window.location.pathname.split("/")[2].split("?")[0]
        : undefined;
    if (originModule) {
        headers["wawi-origin-module"] = originModule;
    }
    const token = sessionStorage.getItem(accessTokenKey);
    if (token) {
        headers["Authorization"] = "Bearer " + token;
    }
    return headers;
};
