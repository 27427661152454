import { reactive } from "vue";
import { Module } from "@ui/clients/generated/wam/modules";

export enum ModuleLoadingStatus {
    INITIAL = "INITIAL",
    DONE = "DONE",
}

type StoreState = {
    modules: { [key: string]: Module };
    status: ModuleLoadingStatus;
};

export const ModulesStore = reactive<StoreState>({
    modules: {},
    status: ModuleLoadingStatus.INITIAL,
});

export const useModulesStore = (): Readonly<StoreState> => ModulesStore;
