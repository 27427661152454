import { WaWiClient } from "@ui/clients";
import {
    ListConfigsFeatureToggles,
    ListConfigsLocations,
    ListConfigsLocationsQueryParams,
    ListConfigsModules,
    ListConfigsModulesQueryParams,
    ListConfigsSettings,
    ListConfigsSettingsQueryParams,
} from "@ui/clients/generated/wam/apirestgateway";
import { ListFeatureTogglesRequest } from "@ui/clients/generated/wam/configs";

const listSettings = (queryParams: ListConfigsSettingsQueryParams) =>
    WaWiClient.fetch({ endpoint: ListConfigsSettings, queryParams });

const listFeatureToggles = (body: ListFeatureTogglesRequest) =>
    WaWiClient.fetch({ endpoint: ListConfigsFeatureToggles, body });

const listLocations = (queryParams: ListConfigsLocationsQueryParams) =>
    WaWiClient.fetch({ endpoint: ListConfigsLocations, queryParams });

const listModules = (queryParams: ListConfigsModulesQueryParams) =>
    WaWiClient.fetch({ endpoint: ListConfigsModules, queryParams });

export const ConfigsClient = {
    listSettings,
    listFeatureToggles,
    listLocations,
    listModules,
};
