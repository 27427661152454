import { reactive } from "vue";
import { AuthPolicy } from "../auth/policies";
import { _setPolicies, PublicUserState } from "../auth/user";
import { CurrentModule } from "../module/current";
import { ModuleScope } from "../module/registry";

// module will be in preview mode if it is inside an iframe
const IS_PREVIEW_MODE = window.frameElement !== null;

const hasGlobalTarget = (): boolean => {
    const scope = CurrentModule.get()?.scope;
    return scope === ModuleScope.INT;
};

const useMockedUserStore = (): Readonly<PublicUserState> => {
    const canGetPolicyMap = !!(window.top as any).getPolicyMap;
    const mockedPolicies: AuthPolicy[] = canGetPolicyMap ? (window.top as any)?.getPolicyMap() : [];
    if (mockedPolicies.length === 0) {
        MockedUserStore.hasPolicy = () => true;
        MockedUserStore.getLocationsForPolicy = () => ["INT", "IT", "MT", "PT", "RS", "ST", "US"];
        return MockedUserStore;
    }

    const hasPolicyInAnyLocation = (policy: AuthPolicy) => mockedPolicies.map((pol) => pol.Id).includes(policy.Id);

    MockedUserStore.hasPolicy = (policy: AuthPolicy | AuthPolicy[]): boolean => {
        if (Array.isArray(policy)) return policy.every(hasPolicyInAnyLocation);

        return hasPolicyInAnyLocation(policy);
    };

    MockedUserStore.getLocationsForPolicy = (policy: AuthPolicy): string[] =>
        hasPolicyInAnyLocation(policy) ? ["INT", "IT", "MT", "PT", "RS", "ST", "US"] : [];

    return MockedUserStore;
};

const MockedUserStore = reactive<PublicUserState>({
    isLoggedIn: true,
    hasPolicy: () => true,
    getLocationsForPolicy: () => ["INT", "IT", "MT", "PT", "RS", "ST", "US"],
    getDomainsForPolicyAndCountry: () => [],
    aadUserId: "1234-1234-1234-1243",
    nexusModUser: "123456789",
    legacyModUser: "u12345",
    displayName: "Joe Schmoe",
    email: "joe.schmoe@example.com",
    userCountries: ["INT"],
    locationsByModule: {},
    isAllowedModule: () => false,
});

const dataComponentMetadata = (componentPolicies: AuthPolicy | AuthPolicy[] | undefined) => {
    if (!WaWiPreview.IS_PREVIEW_MODE || !componentPolicies) {
        return undefined;
    }

    const id = crypto.randomUUID();
    const policies = Array.isArray(componentPolicies)
        ? componentPolicies.map((pol) => pol.Id).join(";")
        : componentPolicies.Id;
    return { id, policies };
};

export const WaWiPreview = {
    IS_PREVIEW_MODE,
    hasGlobalTarget,
    useMockedUserStore,
    dataComponentMetadata,
};
