export const getCountryOptions = (): CountryOptions[] => CountryOptions.sort((a, b) => a.name.localeCompare(b.name));

export type CountryOptions = {
    code: string;
    name: string;
};

const CountryOptions: CountryOptions[] = [
    {
        name: "Austria",
        code: "AT",
    },
    {
        name: "Belgium",
        code: "BE",
    },
    {
        name: "Bulgaria",
        code: "BG",
    },
    {
        name: "Switzerland",
        code: "CH",
    },
    {
        name: "Cyprus",
        code: "CY",
    },
    {
        name: "Czech Republic",
        code: "CZ",
    },
    {
        name: "Germany",
        code: "DE",
    },
    {
        name: "Denmark",
        code: "DK",
    },
    {
        name: "Estonia",
        code: "EE",
    },
    {
        name: "Spain",
        code: "ES",
    },
    {
        name: "Finland",
        code: "FI",
    },
    {
        name: "France",
        code: "FR",
    },
    {
        name: "United Kingdom",
        code: "GB",
    },
    {
        name: "Greece",
        code: "GR",
    },
    {
        name: "Croatia",
        code: "HR",
    },
    {
        name: "Hungary",
        code: "HU",
    },
    {
        name: "Ireland",
        code: "IE",
    },
    {
        name: "International",
        code: "INT",
    },
    {
        name: "Italy",
        code: "IT",
    },
    {
        name: "Lithuania",
        code: "LT",
    },
    {
        name: "Latvia",
        code: "LV",
    },
    {
        name: "North Macedonia",
        code: "MK",
    },
    {
        name: "Malta",
        code: "MT",
    },
    {
        name: "Northern Ireland",
        code: "NI",
    },
    {
        name: "Netherlands",
        code: "NL",
    },
    {
        name: "Poland",
        code: "PL",
    },
    {
        name: "Portugal",
        code: "PT",
    },
    {
        name: "Romania",
        code: "RO",
    },
    {
        name: "Serbia",
        code: "RS",
    },
    {
        name: "Sweden",
        code: "SE",
    },
    {
        name: "Slovenia",
        code: "SI",
    },
    {
        name: "Slovakia",
        code: "SK",
    },
    {
        name: "United States",
        code: "US",
    },
    {
        name: "Fitgap",
        code: "FG",
    },
    {
        name: "Year End Cyprus",
        code: "YC",
    },
    {
        name: "Year End Italy",
        code: "YI",
    },
];
