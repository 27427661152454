import { RouteRecordRaw } from "vue-router";
import LoginErrorPage from "./ui/LoginErrorPage.vue";
import LogoutSuccessPage from "./ui/LogoutSuccessPage.vue";

export const routes: RouteRecordRaw[] = [
    {
        path: "/logout-success",
        name: "LogoutSuccessPage",
        component: LogoutSuccessPage,
    },
    {
        path: "/login-error",
        name: "LoginErrorPage",
        component: LoginErrorPage,
    },
];
