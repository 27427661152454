<script lang="ts" setup>
import { __, ComponentUtils, Icons, useMsgFormatter, WuxButton } from "@ui/components";
import { Datadog } from "./datadog";

const { m } = useMsgFormatter();

const sessionID = Datadog.getSessionID() || "";
const onCopy = () => ComponentUtils.copyToClipboard(sessionID);
</script>

<template>
    <div class="session-id">
        <span>{{ m("ui.libs.about.session-id") }}</span>
        <span>{{ sessionID }}</span>
        <WuxButton :icon="Icons.copy" variant="outline" @click="onCopy" :titleMsg="__('ui.libs.copy-to-clipboard')" />
    </div>
</template>

<style lang="scss" scoped>
.session-id {
    display: flex;
    gap: 1em;
    align-items: center;
}
</style>
