import { reactive } from "vue";
import { MessageOrId } from "../../i18n";

export type WuxToastElementProps = {
    headerMsg?: MessageOrId;
    contentMsg?: MessageOrId;
};

export type WuxToastElementEmits = {
    (e: "close"): void;
};

type ToastController = WuxToastElementProps & {
    onClose?: () => void;
    id: string;
};

type ToastCotrollerState = {
    layers: ToastController[];
};

export const toastStore = reactive<ToastCotrollerState>({ layers: [] });

const close = (id: string) => () => {
    toastStore.layers = toastStore.layers.filter((layer) => layer.id !== id);
};

export const addToast = (props: WuxToastElementProps) => {
    if (toastStore.layers.length === 3) {
        // we need to remove the oldest toast
        toastStore.layers.shift();
    }
    const id = crypto.randomUUID();
    const onClose = close(id);
    const layer = {
        ...props,
        id,
        onClose,
    };
    toastStore.layers.push(layer);
};
