/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { z } from "zod";

export enum ToggleState {
    TOGGLE_STATE_NEW = "TOGGLE_STATE_NEW",
    TOGGLE_STATE_IN_PROGRESS = "TOGGLE_STATE_IN_PROGRESS",
    TOGGLE_STATE_COMPLETED = "TOGGLE_STATE_COMPLETED",
}

export const ToggleStateSchema = z.nativeEnum(ToggleState).catch(ToggleState.TOGGLE_STATE_NEW);
