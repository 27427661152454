/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { z } from "zod";
import { LocationTypeSchema } from "./LocationType";

export const LocationSchema = z.object({
    country: z.string(),
    id: z.string(),
    type: LocationTypeSchema,
    number: z.coerce.bigint(),
    name: z.string(),
});

export type Location = z.infer<typeof LocationSchema>;
