import { computed, InjectionKey, onMounted, onUnmounted, reactive, toRef, watch } from "vue";
import { MessageOrId } from "../../i18n";

export type WuxAccordionProps = {
    /**
     * To pass this property to each accordion in the group and set the 'dense' for each.
     */
    isDense?: boolean;
    /**
     * If 'isExclusive', only one accordion can be open at a time.
     */
    isExclusive?: boolean;
    /**
     * Loading indicator
     */
    isLoading?: boolean;
};

export type WuxAccordionPanelProps = {
    /**
     * To set set accordion as disabled
     */
    isDisabled?: boolean;
    /**
     * To manage the open state of accordion
     */
    isExpanded?: boolean;
    /**
     * To apply 'reveal' style to the accordion panel
     */
    asReveal?: boolean;
    /**
     * Message for title of the accordion
     */
    titleMsg?: MessageOrId;
    /**
     * Text for title of the accordion
     */
    title?: string;
};

/**
 * types to handle provide/inject mechanism
 */
export const accordionInjectionKey: InjectionKey<ReturnType<typeof useAccordionState>["registerAccordionPanel"]> =
    Symbol("accordion");

export const useAccordionState = (props?: WuxAccordionProps) => {
    const openPanelIds = reactive(new Set<symbol>());
    const allIds = reactive(new Set<symbol>());
    const disabledIds = reactive(new Set<symbol>());

    const openAll = () => {
        if (props?.isExclusive) {
            // eslint-disable-next-line no-console
            console.warn("openAll is not allowed when isExclusive is true");
            return;
        }

        allIds.forEach((id) => {
            if (!disabledIds.has(id)) {
                openPanelIds.add(id);
            }
        });
    };

    const closeAll = () => {
        openPanelIds.forEach((id) => {
            if (!disabledIds.has(id)) {
                openPanelIds.delete(id);
            }
        });
    };

    const registerAccordionPanel = (panelProps?: WuxAccordionPanelProps) => {
        const panelId = Symbol();
        const isExpanded = computed(() => openPanelIds.has(panelId));

        onMounted(() => {
            allIds.add(panelId);
        });
        onUnmounted(() => {
            allIds.delete(panelId);
            openPanelIds.delete(panelId);
            disabledIds.delete(panelId);
        });
        watch(
            () => panelProps?.isDisabled,
            () => {
                if (panelProps?.isDisabled) {
                    disabledIds.add(panelId);
                } else {
                    disabledIds.delete(panelId);
                }
            },
            { immediate: true },
        );

        return {
            isDense: toRef(() => props?.isDense),
            isExpanded,

            toggle() {
                if (panelProps?.isDisabled) return;
                const wasExpanded = isExpanded.value;
                if (props?.isExclusive) {
                    openPanelIds.clear();
                }
                if (wasExpanded) {
                    openPanelIds.delete(panelId);
                } else {
                    openPanelIds.add(panelId);
                }
            },

            setExpanded(expanded: boolean) {
                if (panelProps?.isDisabled) return;
                if (expanded) {
                    openPanelIds.add(panelId);
                } else {
                    openPanelIds.delete(panelId);
                }
            },
        };
    };

    return {
        registerAccordionPanel,
        openAll,
        closeAll,
    };
};
