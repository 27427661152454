import { type GoogleDate } from "./runtime";

type ConvertToGoogleDate = {
    (date: Date): GoogleDate;
    (date?: Date): GoogleDate | undefined;
};
const convertToGoogleDate: ConvertToGoogleDate = (date) =>
    date
        ? {
              day: date.getDate(),
              month: date.getMonth() + 1,
              year: date.getFullYear(),
          }
        : (undefined as any);

type ConvertGoogleDate = {
    (date: GoogleDate): Date;
    (date?: GoogleDate): Date | undefined;
};
const convertGoogleDate: ConvertGoogleDate = (date) =>
    date ? new Date(date.year, date.month - 1, date.day) : (undefined as any);

const _withLeadingZero = (num: number) => num.toString().padStart(2, "0");
/**
 * ⚠️ We don't use here date.toISOString().substring(0, 10) as the ISO string always uses the UTC representation.
 *   However, all our input elements use the timezone of the user, hence when the user selects a date "2023-02-08"
 *   this will be potentially not the same as the UTC date.
 *   That's why we need to use the timezone based getters of the date to determine which year/month/day the user sees.
 */
const toShortISOString = (date: Date) =>
    `${date.getFullYear()}-${_withLeadingZero(date.getMonth() + 1)}-${_withLeadingZero(date.getDate())}`;

/**
 * This class is an alternative class for "Date" which will render only the date if called within "JSON.stringify".
 * For all other scenarios it behaves like a usual date and can be used in places where dates are expected.
 *
 * @deprecated We transfer all dates to the backend as GoogleDate, which don't contain any time.
 *             This class caused in the past rather confusion and is nothing different from using Date itself.
 *             We handle every date without its time. When time is important use rather the Timestamp class.
 */
export class DateWithoutTime extends Date {
    toJSON(_?: any): string {
        return toShortISOString(this);
    }

    static toShortISOString = toShortISOString;
}

export const DateUtil = { convertToGoogleDate, convertGoogleDate, toShortISOString };
