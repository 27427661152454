import { reactive, ref } from "vue";
import { FeatureToggle, ToggleState } from "@ui/clients/generated/wam/feature_toggles";
import { LocationSetting, Section } from "@ui/clients/generated/wam/settings";
import { hasAnyPolicyInLocation } from "../auth/user";
import { WaWiCountry } from "../country/country";
import { WaWiLocation } from "../location/location";
import { WaWiPreview } from "../preview/preview";
import { LOG } from "../skeleton/errors";
import { ConfigsClient } from "./ConfigsClient";

const MAXIMUM_LOADED_PAGES = 100;
const WARNING_LOADED_PAGES = 80;
const defaultPageSize = 1000n;

export type ToggleConfig = {
    name: string;
    hideIfActive?: boolean;
};

export enum LoadingStatus {
    INITIAL,
    LOADING,
    READY,
    ERROR,
}

export const featureTogglesKey = "featureToggles";

type WawiConfigsState = {
    configsStatus: LoadingStatus; // Informs when settings are loaded or if there was an error.
    GetSettingValue: (section: string, name: string) => string | undefined; // Returns an existing setting for the country and location the UI is operating in, or undefined otherwise
};

type FeatureTogglesState = {
    featureTogglesStatus: LoadingStatus; // Informs when Feature Toggles are loaded or if there was an error.
    isFeatureEnabled: (name: string) => boolean; // Returns if certain Feature Toggle is enabled or not for the country and location the UI is operating in. False is returned if the toggle is not found.
};

type configsMap = Record<string, Record<string, Record<string, Record<string, string>>>>;
type featureTogglesMap = Record<string, boolean>;

const initialWawiConfigsState: WawiConfigsState = {
    configsStatus: LoadingStatus.INITIAL,
    // eslint-disable-next-line
    GetSettingValue: (section: string, name: string) => undefined,
};

const initialFeatureTogglesState: FeatureTogglesState = {
    featureTogglesStatus: LoadingStatus.INITIAL,
    // eslint-disable-next-line
    isFeatureEnabled: (name: string) => false,
};

// Don't use in vue components, only in ts services
export const WawiConfigsStore = reactive(initialWawiConfigsState);
export const FeatureTogglesStore = reactive(initialFeatureTogglesState);

// Don't use in ts services, only in vue components
export const useWawiConfigsStore = (): Readonly<WawiConfigsState> => WawiConfigsStore;
export const useFeatureTogglesStore = (): Readonly<FeatureTogglesState> => FeatureTogglesStore;

const createConfigsMap = (sectionSettings: LocationSetting[]) => {
    const configs: configsMap = {};

    sectionSettings.forEach((setting) => {
        const section = setting.section.toString();

        if (!configs[setting.country]) {
            configs[setting.country] = {};
        }
        if (!configs[setting.country][section]) {
            configs[setting.country][section] = {};
        }
        if (!configs[setting.country][section][setting.name]) {
            configs[setting.country][section][setting.name] = {};
        }

        configs[setting.country][section][setting.name][setting.location] = setting.value;
    });

    return configs;
};

const createFeatureTogglesMap = (featureToggles: FeatureToggle[]) => {
    const featureTogglesMap: featureTogglesMap = {};
    const country = WaWiCountry.get();
    const location = WaWiCountry.isInternational() ? "INT" : WaWiLocation.get();

    if (!location) {
        return featureTogglesMap;
    }

    featureToggles
        .filter((featureToggle) => featureToggle.country === country)
        .forEach((featureToggle) => {
            const desiredActivation = featureToggle.activations.find((activation) => activation.location === location);

            if (!desiredActivation) {
                return;
            }

            featureTogglesMap[featureToggle.name] = desiredActivation.isActive;
        });

    return featureTogglesMap;
};

export const loadWawiConfigs = async (
    sections: Section[], // Only settings belonging to sections specified here are loaded (usually, current module + SHARED).
): Promise<void> => {
    if (WawiConfigsStore.configsStatus !== LoadingStatus.INITIAL || sections.length === 0) {
        WawiConfigsStore.configsStatus = LoadingStatus.READY;
        return;
    }

    if (!hasAnyPolicyInLocation()) {
        // do not try to load settings for an invalid token
        WawiConfigsStore.configsStatus = LoadingStatus.ERROR;
        return;
    }

    const country = WaWiCountry.get();

    WawiConfigsStore.configsStatus = LoadingStatus.LOADING;

    const configs = ref<configsMap>({});

    WawiConfigsStore.GetSettingValue = (section: string, name: string) => {
        if (WaWiCountry.isInternational()) {
            return configs.value?.[country]?.[section]?.[name]?.["INT"];
        }

        const location = WaWiLocation.get();
        if (!location) {
            LOG.errorMsg("Location is undefined, returning undefined config value.");
            return undefined;
        }

        return (
            configs.value?.[country]?.[section]?.[name]?.[location] ??
            configs.value?.[country]?.[section]?.[name]?.["CTRY"]
        );
    };

    let pageNumber = 0;
    let pageToken = "";
    const settings: LocationSetting[] = [];
    do {
        try {
            const settingsResponse = await ConfigsClient.listSettings({
                sections,
                pageToken,
                pageSize: defaultPageSize,
            });
            settings.push(...settingsResponse.settings);
            pageToken = settingsResponse.nextPageToken;
            pageNumber++;
        } catch (e) {
            LOG.caught(e, "SETTINGS_UI_ALERT_WAM: loading the settings failed");
            WawiConfigsStore.configsStatus = LoadingStatus.ERROR;
            return;
        }
    } while (!!pageToken && pageNumber < MAXIMUM_LOADED_PAGES);

    if (pageToken !== "") {
        LOG.errorMsg("SETTINGS_UI_ALERT_WAM: number of loaded pages surpassed maximum allowed");
        WawiConfigsStore.configsStatus = LoadingStatus.ERROR;
        return;
    }

    if (pageNumber > WARNING_LOADED_PAGES) {
        LOG.errorMsg("number of loaded pages surpassed warning threshold");
    }

    configs.value = createConfigsMap(settings);
    WawiConfigsStore.configsStatus = LoadingStatus.READY;
};

export const parseLocalFeatureToggles = (value: string): FeatureToggle[] =>
    value.split(";").flatMap((featureToggle) => {
        const [featureToggleName, truthValue] = featureToggle.split("=").map((s) => s.trim());

        if (!featureToggleName) return [];

        const isEnabled = truthValue?.toUpperCase();
        if (isEnabled !== "TRUE" && isEnabled !== "FALSE") return [];

        const country = WaWiCountry.get();
        const location = WaWiLocation.get();

        if (WaWiCountry.isInternational()) {
            return [
                {
                    name: featureToggleName,
                    country,
                    activations: [
                        {
                            location: "INT",
                            isActive: isEnabled === "TRUE",
                        },
                    ],
                    state:
                        isEnabled === "TRUE"
                            ? ToggleState.TOGGLE_STATE_COMPLETED
                            : ToggleState.TOGGLE_STATE_IN_PROGRESS,
                },
            ];
        }

        if (!location) return [];

        return [
            {
                name: featureToggleName,
                country,
                activations: [
                    {
                        location,
                        isActive: isEnabled === "TRUE",
                    },
                ],
                state: ToggleState.TOGGLE_STATE_IN_PROGRESS,
            },
        ];
    });

const getLocalFeatureToggles = async (): Promise<FeatureToggle[]> => {
    const featureTogglesValue = localStorage.getItem(featureTogglesKey);
    return featureTogglesValue ? parseLocalFeatureToggles(featureTogglesValue) : [];
};

const getRemoteFeatureToggles = async (names: string[]): Promise<FeatureToggle[]> => {
    if (names.length === 0) return [];

    if (!hasAnyPolicyInLocation()) {
        // do not try to load feature toggles for an invalid token
        return [];
    }

    let pageNumber = 0;
    let pageToken = "";
    const remoteToggles: FeatureToggle[] = [];

    do {
        // error handling is done by the parent function
        const rspFeatureToggles = await ConfigsClient.listFeatureToggles({
            pageToken,
            pageSize: defaultPageSize,
            and: {
                filters: [
                    {
                        nameIn: {
                            names,
                        },
                    },
                ],
            },
        });
        remoteToggles.push(...rspFeatureToggles.featureToggles);
        pageToken = rspFeatureToggles.nextPageToken;
        pageNumber++;
    } while (!!pageToken && pageNumber < MAXIMUM_LOADED_PAGES);

    if (pageToken !== "") {
        throw new Error("number of loaded pages surpassed maximum allowed");
    }

    if (pageNumber > WARNING_LOADED_PAGES) {
        LOG.errorMsg("number of loaded pages surpassed warning threshold");
    }

    return remoteToggles;
};

export const loadFeatureToggles = async (
    names: string[], // Only feature toggles with name belonging to this list are loaded.
): Promise<void> => {
    if (FeatureTogglesStore.featureTogglesStatus !== LoadingStatus.INITIAL) return;

    if (WaWiPreview.IS_PREVIEW_MODE) {
        FeatureTogglesStore.featureTogglesStatus = LoadingStatus.READY;
        FeatureTogglesStore.isFeatureEnabled = (): boolean => true;
        return;
    }

    FeatureTogglesStore.featureTogglesStatus = LoadingStatus.LOADING;

    const localFeatureToggles = ref<featureTogglesMap>({});
    const remoteFeatureToggles = ref<featureTogglesMap>({});

    FeatureTogglesStore.isFeatureEnabled = (name: string): boolean =>
        localFeatureToggles.value?.[name] !== undefined
            ? localFeatureToggles.value[name]
            : !!remoteFeatureToggles.value?.[name];

    let localResponse: FeatureToggle[] = [];
    let remoteResponse: FeatureToggle[] = [];

    try {
        [localResponse, remoteResponse] = await Promise.all([getLocalFeatureToggles(), getRemoteFeatureToggles(names)]);
    } catch (e) {
        LOG.caught(e, "FEATURE_TOGGLE_UI_ALERT_WAM: could not fetch feature toggles");
        FeatureTogglesStore.featureTogglesStatus = LoadingStatus.ERROR;
        return;
    }

    localFeatureToggles.value = createFeatureTogglesMap(localResponse);
    remoteFeatureToggles.value = createFeatureTogglesMap(remoteResponse);

    FeatureTogglesStore.featureTogglesStatus = LoadingStatus.READY;
};

export const isSettingTruthBoolean = (value: string | undefined, trueValues?: string[]): boolean => {
    if (value === undefined) return false;
    return (trueValues?.map((v) => v.toLowerCase()) || ["true", "1"]).includes(value.toLowerCase());
};
