/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { type Endpoint, HTTPMethod, type OmitOptionalDeep } from "../../../src/runtime";
import { type ListLocationsRequest } from "../configs/ListLocationsRequest";
import { type ListLocationsResponse, ListLocationsResponseSchema } from "../configs/ListLocationsResponse";

export type ListConfigsLocationsQueryParams = OmitOptionalDeep<ListLocationsRequest, "">;

export const ListConfigsLocations: Endpoint<ListLocationsResponse, void, void, ListConfigsLocationsQueryParams> = {
    method: HTTPMethod.GET,
    path: "/wam/configs/locations",
    parser: ListLocationsResponseSchema.parse,
};
