import { WaWiClient } from "@ui/clients";
import { refreshTokenIfOld } from "../auth/refresh";
import { AuthService } from "../auth/services/AuthService";
import { createAuthHeaders } from "../auth/user";
import { createLocationHeaders } from "../location/location";
import { FetchParams, MockedEndpoints, WaWiMockClient } from "../preview/WaWiMockClient";
import { SkeletonStoreLoading } from "./store";

export const getAdditionalWaWiFetchHeaders = () => ({ ...createAuthHeaders(), ...createLocationHeaders() });

const ONE_MINUTE = 60 * 1000;
export const initClientConfig = (mockedEndpoints?: MockedEndpoints) => {
    const mockedFetch = mockedEndpoints
        ? (args: FetchParams) => WaWiMockClient.fetch(mockedEndpoints, args)
        : undefined;

    WaWiClient.configure({
        onUnauthorized: AuthService.onUnauthorized,
        getAdditionalHeaders: getAdditionalWaWiFetchHeaders,
        onStartLoading: async (noLoadingIndicator) => {
            // NOTE: this function is called at the beginning of each fetch request!
            if (!noLoadingIndicator) {
                SkeletonStoreLoading.start();
            }
            await refreshTokenIfOld(ONE_MINUTE);
        },
        onEndLoading: (noLoadingIndicator) => {
            // NOTE: this function is called at the end of each fetch request!
            if (!noLoadingIndicator) {
                SkeletonStoreLoading.end();
            }
        },
        mockedFetch,
    });
};
