<script lang="ts" setup>
import { __, DeadEndAction, MessageOrId, WuxDeadEnd } from "@ui/components";
import { LOGIN_PATH } from "@ui/libs/auth";
// the entry-ui is allowed to use these, but we don't export it for everyone to use
// eslint-disable-next-line no-restricted-imports
import { externalLogout, internalLogout, LoginErrorReason } from "@ui/libs/auth/guard";
// eslint-disable-next-line no-restricted-imports
import { Datadog } from "@ui/libs/skeleton/datadog";

const redirectToLogin = () => window.location.replace(LOGIN_PATH);
const sessionIdHint: MessageOrId = {
    id: __("wam.entry.login-error.session-id-hint"),
    values: { session: Datadog.getSessionID() },
};

const params = (() => {
    const params = new URLSearchParams(window.location.search);
    return {
        reason: params.get("reason"),
        email: decodeURI(params.get("email") || "") || null,
    };
})();

const props = ((): {
    titleMsg: MessageOrId;
    contentMsg: MessageOrId;
    primary?: DeadEndAction;
    secondary?: DeadEndAction;
    footNoteMsg?: MessageOrId;
} => {
    switch (params.reason) {
        case LoginErrorReason.LOGGED_IN:
            return {
                titleMsg: __("wam.entry.login-error.logged-in.title"),
                contentMsg: __("wam.entry.login-error.logged-in.description"),
                primary: {
                    labelMsg: __("wam.entry.log-back-in"),
                    onClick: async () => {
                        await internalLogout();
                        redirectToLogin();
                    },
                },
                secondary: {
                    labelMsg: __("wam.entry.to-launchpad"),
                    onClick: () => window.location.replace("/"),
                },
                footNoteMsg: sessionIdHint,
            };
        case LoginErrorReason.INVALID_STATE:
            return {
                titleMsg: __("wam.entry.login-error.invalid-state.title"),
                contentMsg: __("wam.entry.login-error.invalid-state.description"),
                primary: {
                    labelMsg: __("wam.entry.login-action"),
                    onClick: redirectToLogin,
                },
                footNoteMsg: sessionIdHint,
            };
        case LoginErrorReason.NO_POLICIES:
            return {
                titleMsg: __("wam.entry.login-error.no-policies.title"),
                contentMsg: __("wam.entry.login-error.no-policies.description"),
                primary: {
                    labelMsg: __("wam.entry.switch-account"),
                    onClick: externalLogout,
                },
                footNoteMsg: {
                    id: __("wam.entry.login-error.email-hint"),
                    values: { email: params.email },
                },
            };
        case LoginErrorReason.INVALID_ACCOUNT:
            return {
                titleMsg: __("wam.entry.login-error.invalid-account.title"),
                contentMsg: __("wam.entry.login-error.invalid-account.description"),
                primary: {
                    labelMsg: __("wam.entry.switch-account"),
                    onClick: externalLogout,
                },
                footNoteMsg: {
                    id: __("wam.entry.login-error.email-hint"),
                    values: { email: params.email },
                },
            };
        case LoginErrorReason.UNAVAILABLE:
            return {
                titleMsg: __("wam.entry.login-error.unavailable.title"),
                contentMsg: __("wam.entry.login-error.unavailable.description"),
                primary: {
                    labelMsg: __("wam.entry.retry"),
                    onClick: redirectToLogin,
                },
                footNoteMsg: sessionIdHint,
            };
        default:
            return {
                titleMsg: __("wam.entry.login-error.title"),
                contentMsg: __("wam.entry.login-error.description"),
                primary: {
                    labelMsg: __("wam.entry.retry"),
                    onClick: redirectToLogin,
                },
                footNoteMsg: sessionIdHint,
            };
    }
})();
</script>
<template>
    <WuxDeadEnd
        :titleMsg="props.titleMsg"
        :contentMsg="props.contentMsg"
        :primary="props.primary"
        :secondary="props.secondary"
        :footNoteMsg="props.footNoteMsg"
    />
</template>
