<script lang="ts" setup>
import { MessageOrId, useMsgFormatter } from "../../i18n";
import { Icon, Icons } from "../../icons";

export type ButtonVariant = "default" | "text" | "outline";

const props = withDefaults(
    defineProps<{
        /**
         * You can use as values here e.g. `Icons.x`.
         */
        src: Icon;
        size?: number;
        /**
         * Can be any CSS color value.
         */
        color?: string;
        title?: string;
        titleMsg?: MessageOrId;
    }>(),
    { size: 24, color: "currentColor" },
);

const { rawM } = useMsgFormatter();
</script>

<template>
    <div
        :title="rawM(props.titleMsg, props.title)"
        v-if="props.src === Icons.spinner"
        class="wux-icon--spinner"
        :style="{ '--wux-spinner-size': size + 'px' }"
    />

    <svg v-else class="wux-icon" :fill="props.color" viewBox="0 0 32 32" :style="{ '--_wux-icon-size': size + 'px' }">
        <path :d="props.src"></path>
        <title v-if="rawM(props.titleMsg, props.title)">{{ rawM(props.titleMsg, props.title) }}</title>
    </svg>
</template>
<style lang="scss">
.wux-icon {
    width: var(--_wux-icon-size);
    height: var(--_wux-icon-size);
}
// hack: duplicated class selector for higher specificity
.wux-icon--spinner.wux-icon--spinner {
    // Should match the styles from `WuxSpinner`
    --wux-spinner-size: 22px;
    width: var(--wux-spinner-size);
    height: var(--wux-spinner-size);
    border-radius: 50%;
    position: relative;
    animation: 0.85s cubic-bezier(0.43, 0.35, 0.57, 0.84) 0s infinite normal none running wux-icon-spinner-rotate;

    &::before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        inset: 0;
        border-radius: 50%;
        border: 1px solid var(--wawi-color-primary-500);
        animation: 0.85s cubic-bezier(0.43, 0.35, 0.57, 0.84) 0s infinite normal none running wux-icon-spinner-clip;
    }
}

@keyframes wux-icon-spinner-rotate {
    0% {
        transform: rotate(-300deg);
    }
    100% {
        transform: rotate(60deg);
    }
}

@keyframes wux-icon-spinner-clip {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    100% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
}
</style>
