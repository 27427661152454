type RequiredKeys<TObj, TKeys extends keyof TObj> = TObj & { [key in TKeys]-?: NonNullable<TObj[key]> };

export const getGroupedBy = <TKey extends string, TObj extends { [key in TKey]?: string }>(
    key: TKey,
    data: TObj[],
): { notGrouped: TObj[]; grouped: Array<[string, RequiredKeys<TObj, TKey>[]]> } => {
    const notGrouped: TObj[] = [];
    const grouped: Record<string, RequiredKeys<TObj, TKey>[]> = {};

    for (const v of data) {
        const group = v[key] as string | undefined;
        if (group) {
            if (!(group in grouped)) {
                grouped[group] = [];
            }
            grouped[group].push(v as RequiredKeys<TObj, TKey>);
        } else {
            notGrouped.push(v);
        }
    }

    return {
        notGrouped,
        grouped: Object.entries(grouped),
    };
};
