<script lang="ts" setup>
import { __ } from "../../i18n";
import WuxButton from "../WuxButton/WuxButton.vue";

const reload = () => {
    location.reload();
};
</script>

<template>
    <WuxButton variant="outline" :labelMsg="__('ui.components.load-new-version')" @click="reload" isDense />
</template>
